/**
 * Created by moolsbytheway on 9/3/17.
 */

import React from "react";
import MaterialIcon from "../MaterialIcon";

export default class CarouselCommand extends React.Component {
    constructor(props) {
        super(props);

        this.togglePlay = this.togglePlay.bind(this);
    }

    togglePlay() {
        this.props.isPaused ? this.props.play() : this.props.pause();

    }

    render() {
        let playState = this.props.isPaused ? "" : "hide";
        let pauseState = this.props.isPaused ? "hide" : "";
        return (
            <div className="carouselCommand" onClick={this.togglePlay}>
                <MaterialIcon className={playState} icon="play_circle_outline"/>
                <MaterialIcon className={pauseState} icon="pause_circle_outline"/>
            </div>
        )
    }

}