import React from 'react';
import MaterialIcon from "../MaterialIcon";


const meta = require("../../translations/meta.json");


export default class Mail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: 0, // show all mails
            filtered: '',
            selectedMail: '',
            mailActive: false
        }
        this.archiveMails = this.archiveMails.bind(this)
    }

    archiveMails() {
        this.props.archiveMails(this.state.selectedMail.id, this.state.selectedMail.archived);
    }

    render() {
        return <div className={this.state.mailActive ? "mail-container active" : "mail-container"}>
            <button
                style={{display: "flex", alignItems: "center"}}
                hidden={this.state.mailActive}
                className={this.props.mails.length > 0 ? "mail-button active" : "mail-button"}
                onClick={() => {
                    this.setState({
                        mailActive: true,
                        filter: 0, // show all mails
                        filtered: '',
                        selectedMail: ''
                    }, this.props.getMails())
                }}
            >
                <MaterialIcon icon={"email"}/>{this.props.mails ? this.props.mails.filter(m => {
                return m.read === "0"
            }).length : 0} message{this.props.mails && this.props.mails.length > 1 && "s"}
            </button>
            <span onClick={() => {
                this.setState({
                    mailActive: false,
                    selectedMail: ''
                })
            }} style={{display: this.state.mailActive ? "block" : "none"}}
                  className={"close-btn"}>close</span>
            <div className="mail-holder"
                 style={{display: this.state.mailActive ? "block" : "none"}}>
                <h3><b><MaterialIcon icon={"email"}/>Mails</b>
                    <div style={{float: 'right', display: 'flex'}}>
                        <button className={this.state.filter === 0 ? "active" : ""} onClick={() => {
                            this.filterMails(0, null, true)
                        }
                        }>Inbox
                        </button>
                        <button className={this.state.filter === 1 ? "active" : ""} onClick={() => {
                            this.filterMails(1, null, true)
                        }
                        }>Unread
                        </button>
                        <button className={this.state.filter === 2 ? "active" : ""} onClick={() => {
                            this.filterMails(2, null, true)
                        }
                        }>Archived
                        </button>
                    </div>
                </h3>
                <table>
                    <tbody>
                    {this.state.filtered && this.state.filtered.map((mail, i) => {
                        return <tr key={i}>
                            <td>
                                <div
                                    className={mail.read === "1" ? mail.id === this.state.selectedMail.id ? "active read" : "read" : ""}
                                    onClick={() => {
                                        this.OpenMessage(mail);
                                    }
                                    }>
                                    <h6>{mail.name}</h6>
                                    <p>{mail.email}</p>
                                </div>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
                <div className={"mail-content"}>
                    {!this.state.selectedMail && <p>Please select a message</p>}
                    {this.state.selectedMail &&
                    <p>
                        <b>{this.state.selectedMail.name} &lt;</b>{this.state.selectedMail.email}&gt;
                        <i style={{marginLeft: 10}}>[{this.state.selectedMail.created_at}]</i>
                        <MaterialIcon title={this.state.selectedMail.archived === "1" ? "Unarchive" : "Archive"}
                                      onClick={this.archiveMails} float={"right"}
                                      icon={this.state.selectedMail.archived === "1" ? "unarchive" : "archive"}/></p>}
                    {this.state.selectedMail &&
                    <p>{this.state.selectedMail.message}</p>}
                </div>
            </div>
        </div>
    }

    OpenMessage(mail) {
        this.setState({
            selectedMail: mail
        }, () => {
            if (mail.read === "0") this.props.updateMails(mail.id);
        });
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.mails) this.filterMails(this.state.filter, nextProps.mails);
    }

    componentWillMount() {
        this.filterMails(this.state.filter, null);
    }

    filterMails(filter, receivedMails, resetSelectedMsg) {
        if (!receivedMails) receivedMails = this.props.mails;
        if (resetSelectedMsg) this.setState({selectedMail: ''});

        this.setState({filter});
        let filtered = receivedMails;

        switch (filter) {
            case 0 : // i
                filtered = receivedMails.filter(mail => {
                    return mail.archived === "0";
                });
                break;
            case 1 : // unread
                filtered = receivedMails.filter(mail => {
                    return mail.read === "0";
                });
                break;
            case 2: // archived
                filtered = receivedMails.filter(mail => {
                    return mail.archived === "1";
                });
                break;
            default:
                break;
        }

        this.setState({filtered});
    }
}
