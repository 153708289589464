/**
 * Created by moolsbytheway on 8/8/17.
 */

import React from "react";

export default function MaterialIcon(props) {
    return (
        <i id={props.id}
           title={props.title ? props.title : ''}
           onClick={props.onClick}
           className={"material-icons t_shadow-0 " + props.className}
           style={{color: props.color, float: props.float}}>{props.icon}</i>
    );
}

MaterialIcon.defaultProps = {
    float: 'none',
    icon: 'panorama_fish_eye',
    className: ''
};