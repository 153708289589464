import React from 'react';
import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";
import Editor from "../Editor";
import ReactTable from "react-table";

import "react-table/react-table.css";
import {confirmAlert} from "react-confirm-alert";
import {Link} from "react-router-dom";
import {API_URL} from "../../Static";


const meta = require("../../translations/meta.json");

export default class Admin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            post: {
                id: '',
                title: '',
                image: '',
                text: ''
            },
            valid: false,
            success: false,
            error: false,
            loading: false,
            posts: ''
        }
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.save = this.save.bind(this)
        this.getPosts = this._getPosts.bind(this)
        this.deletePost = this._deletePost.bind(this)
        this.getBase64 = this.getBase64.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
        this.checkValid = this.checkValid.bind(this)
    }


    deleteImage() {
        const post = this.state.post;
        post.image = '';
        this.setState({post})
    }

    getBase64(file, cb) {
        if (!file) {
            const post = this.state.post;
            post.image = '';
            this.setState({post})
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            // console.log('Error: ', error);
        };
    }

    uploadFile(e) {
        const post = this.state.post;
        if (!e.target.files || e.target.files.length === 0) {
            post.image = ''
            this.setState({post});
        } else {
            this.getBase64(e.target.files[0], (base64image) => {
                post.image = base64image
                this.setState({post, valid: this.checkValid()});
            });
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        const oldblogPostid = this.props.match.params.blogPost;
        const nextblogPostid = nextProps.match.params.blogPost;
        if (oldblogPostid === nextblogPostid) return;

        try {
            fetch(API_URL + "/api/posts/" + atob(nextblogPostid))
                .then(response => response.json())
                .then(post => {
                    this.setState({post: post})
                })
                .catch(error => {
                    // // console.log("error", error);
                })
                .catch(error => {
                    // // console.log("error", error);
                });
        } catch (e) {
            // console.log(e)
        }

    }

    componentWillMount() {
        this.getPosts();
        let postId = '';
        try {
            postId = atob(this.props.match.params.blogPost);
        } catch (e) {
            this.setState({
                post: {
                    id: '',
                    title: '',
                    image: '',
                    text: ''
                }
            });
            return;
        }

        fetch(API_URL + "/api/posts/" + postId)
            .then(response => response.json())
            .then(post => {
                this.setState({post: post})
            })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });

    }


    _getPosts() {
        fetch(API_URL + "/api/allposts", {
            method: 'get', headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((resp) => resp.json())
            .then(posts => {
                this.setState({
                    posts
                })
            })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });
    }

    save() {
        if (!this.state.valid) return;

        this.setState({
            loading: true
        });
        fetch(this.state.post.id ?
            (API_URL + "/api/post/" + this.state.post.id)
            : API_URL + "/api/post", {
            method: this.state.post.id ? 'PUT' : 'POST',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.post)
        })
            .then(response => response.json())
            .then((post) => {
                if (!this.state.post.id) {
                    this.setState({
                        success: true,
                        loading: false,
                        post: {
                            id: '',
                            title: '',
                            image: '',
                            text: ''
                        }, valid: this.checkValid()
                    })
                } else {
                    this.setState({
                        post: post,
                        success: true,
                        loading: false
                    }, () => {
                        // console.log("saved", post);
                    })
                }
            }).catch((error) => {
            this.setState({
                error: true,
                loading: false
            })
        })
            .catch(error => {
                // // console.log("error", error);
            }).finally(() => {
            this.getPosts();
        })
    }

    handleChange(html) {
        const post = this.state.post;
        post.text = html;
        this.setState({post, valid: this.checkValid()})

        this.setState({
            success: false,
            error: false,
            loading: false
        });
    }

    handleTitleChange(e) {
        const post = this.state.post;
        post.title = e.target.value;
        this.setState({post}, () => {
            // console.error(this.state);
        });
        this.setState({
            success: false,
            error: false,
            loading: false, valid: this.checkValid()
        });
    }

    checkValid() {
        return this.state.post.text && this.state.post.image && this.state.post.title;
    }

    _deletePost(post) {
        confirmAlert({
            title: "Destructive action",
            message: "Are sure you want to delete post :" + post.title,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        fetch(API_URL + "/api/posts/" + post.id, {
                            method: 'delete', headers: {
                                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(res => {
                                // // console.log(res);
                            })
                            .catch(error => {
                                // console.error(error);
                            }).finally(() => {
                            this.getPosts();
                        })
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    render() {

        return <div className={"admin"}>

            <Helmet>
                <title>United Services | Admin</title>
                <meta name="description"
                      content="Gallery"
                />
            </Helmet>

            <BigTitle title={this.state.post.id ? "Edit Post" : "Create new Post"}/>

            <div className={"r status"}>
                {!this.state.success && !this.state.valid &&
                <div className="c-1-1 valid">Please fill the blanks</div>}
                {this.state.loading && <div className="c-1-1 loading">Saving...</div>}
                {!this.state.loading && this.state.error &&
                <div className="c-1-1 error">Error while saving post, retry.</div>}
                {!this.state.loading && this.state.success && <div className="c-1-1 success">Post saved</div>}
            </div>
            {!this.state.loading && <div className={"r admin-panel"}>
                <div className={'c-1-3 left-2 '}>
                    <button disabled={!this.state.valid} className={"admin-button"} onClick={this.save}>Save
                    </button>
                </div>
                <div className={"c-1-1"} style={{textAlign: "left"}}>
                    <h4>Post title</h4>
                    <input placeholder={"Post title"} type={"text"} value={this.state.post.title}
                           onChange={this.handleTitleChange}/>
                </div>
                <div className={"c-1-1"} style={{textAlign: "left"}}>
                    <h4>Post cover image</h4>
                    {this.state.post.image && <div className={"product-image-actions actions"}>
                        <i className={"delete"} onClick={this.deleteImage}>
                            <spzn>delete image</spzn>
                        </i>
                    </div>}
                    {this.state.post.image &&
                    <img style={{border: "1px solid #aaa", maxWidth: 250}} alt={this.state.post.title}
                         src={this.state.post.image}/>}
                </div>
                <div className={"c-1-1"}>
                    <input placeholder={"Post cover image"} type={"file"} name={"image"}
                           onChange={this.uploadFile}/>
                    <strong>Note:
                        if the post image is not set the first image in the post body will be set as the post
                        image</strong>
                </div>
            </div>}
            {!this.state.loading && <div className={"r"}>
                <div className={"c-1-1"}>
                    <Editor placeholder={'Write something...'}
                            editorHtml={this.state.post.text}
                            handleChange={this.handleChange}
                    />
                </div>
            </div>}


            {this.state.posts && <BigTitle title={"Posts"}/>}
            {this.state.posts && <ReactTable
                data={this.state.posts}
                defaultPageSize={5}
                style={{
                    height: "400px"
                }}
                columns={[

                    {
                        Cell: ({original}) => {
                            return (
                                <Link to={"/blog/" + btoa(original.id)}>{original.title}</Link>
                            )
                        },
                        Header: "Title",
                        accessor: "title",
                        minWidth: 300
                    },
                    {
                        Header: "Last Update",
                        accessor: "updated_at",
                        minWidth: 300
                    },
                    {
                        Header: "Created at",
                        accessor: "created_at",
                        minWidth: 300
                    },
                    {
                        accessor: '',
                        Cell: ({original}) => {
                            return (
                                <div>
                                    <Link to={"/admin/posts/" + btoa(original.id)}
                                          style={{marginRight: 5, cursor: "pointer"}}>Edit</Link>
                                    <a onClick={this.deletePost.bind(this, original)}
                                       style={{color: "red", cursor: "pointer"}}>Delete</a>
                                </div>
                            )
                        },
                        Header: "Actions"
                    }
                ]}
            />}
        </div>
    }

}
