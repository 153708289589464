import React from 'react';
import ReactDOM from 'react-dom';

import {addLocaleData} from 'react-intl';
import AppWrapper from './components/AppWrapper';
import registerServiceWorker from './registerServiceWorker';

import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';
import locale_ar from 'react-intl/locale-data/ar';

import './assets/css/fonts.css';
import './assets/css/typo.css';
import './assets/css/layout.css';
import './assets/scss/index.scss';
import './assets/scss/home.scss';

addLocaleData([...locale_en, ...locale_fr, ...locale_ar]);

const navigatorLang = navigator.language.split(/[-_]/)[0];

ReactDOM.render(<AppWrapper lang={navigatorLang}/>, document.getElementById('root'));
registerServiceWorker();
