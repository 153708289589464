import React from "react";
import {FormattedMessage} from "react-intl";


const BigTitle = (props) => {
    const theStyle = props.title_style;
    const isDefault = !(props.h1 || props.h2 || props.h3 || props.h4 || props.h5 || props.h6);

    return <div className={"r"}>
        <div className={"c-1-1"} style={{textAlign: "center"}}>
            {props.icon && <img style={{maxHeight: 75}} src={props.icon}/>}
            <div className={"title"}
                 style={theStyle}>
                <div style={props.sub_style} className={"title-text"}>
                    {(props.h1 || isDefault) && <h1 className={"black title"}><FormattedMessage id={props.title}/></h1>}
                    {props.h2 && <h2 className={"black title"}><FormattedMessage id={props.title}/></h2>}
                    {props.h3 && <h3 className={"black title"}><FormattedMessage id={props.title}/></h3>}
                    {props.h4 && <h4 className={"black title"}><FormattedMessage id={props.title}/></h4>}
                    {props.h5 && <h5 className={"black title"}><FormattedMessage id={props.title}/></h5>}
                    {props.h6 && <h6 className={"black title"}><FormattedMessage id={props.title}/></h6>}
                </div>
            </div>
        </div>
    </div>
};

export default BigTitle;
