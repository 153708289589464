import React, {createContext} from 'react';

import fr from "../assets/img/flags/fr.jpg";
import en from "../assets/img/flags/uk.jpg";

import MaterialIcon from "./MaterialIcon";

const languages = [
    {code: "en", name: "English", icon: en},
    {code: "fr", name: "Français", icon: fr}
];


//export const LanguageChooserContext = createContext();

class LangItem extends React.Component {
    constructor() {
        super();
        this.ChangLang = this._ChangLang.bind(this);
    }

    _ChangLang() {
        this.props.ChangLang(this.props.lang.code);
    }

    render() {
        let lang = this.props.lang;

        return <div className={"lang-item"} onClick={this.ChangLang}>
            <div className={"flag"} style={{backgroundImage: "url(" + lang.icon + ")"}}>
                <div className={"text-overlay"}>
                    <h4>{lang.name}</h4>
                </div>
            </div>
        </div>
    }
}

export const LanguageChooserContext = createContext();

export class LanguageChooser extends React.Component {

    render() {
        return <LanguageChooserContext.Consumer>
            {({toggleChooser, ChangLang}) => (
                <div className={"overlay language-overlay"}>
                    <div className={"close-button"}>
                        <MaterialIcon icon={"close"} onClick={toggleChooser}/>
                    </div>
                    <div className={"r"}>
                        <div className="languages-holder c-3-5 left-1">
                            {languages.map((item, i) => {
                                return <LangItem key={i}
                                                 lang={item}
                                                 ChangLang={ChangLang}
                                />
                            })
                            }
                        </div>
                    </div>
                </div>)}
        </LanguageChooserContext.Consumer>
    }
}
