import React from 'react';
import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";


import ba from '../../assets/img/staff/ba.jpg';
import dahi from '../../assets/img/staff/dahi.jpg';
import fatma from '../../assets/img/staff/fatma.jpg';
import hanena from '../../assets/img/staff/hanena.jpg';
import elvil from '../../assets/img/staff/elvil.jpg';
import kaem from '../../assets/img/staff/kaem.jpg';


const meta = require("../../translations/meta.json");

const staff = [
    {
        name: "Sidi Mohamed KAEM",
        position: "Co-Founder, CEO",
        image: kaem,
        desc: <div className={"kaem"}>
            <p>Eng. Sidi Mohamed Kaem holds a BS in Agriculture Mechanization from Basra University in Iraq (1998).
                In
                2002, Sidi Mohamed enrolled in the American Academy in Los Angeles, CA, where he partook in Advanced
                English
                language studies.</p>

            <p>Mr. Kaem’s strong sense of initiative in entrepreneurship and pleasant personality allowed him to expand
                his
                business portfolios by building highly placed connections and partnerships across the Atlantic. </p>
            <p>This
                duly
                justifies the man’s merit as he continues to chair an extensive number of executive management positions
                in
                his home country.</p>

            <p>Thus, <b>Mr. Sidi Mohamed Kaem</b> is:</p>

            <p>
                <ul>
                    <li>
                        Board member of the U.S-Mauritania business forum from 09.25.2019 for two year term.
                    </li>
                    <li>
                        The President of National Express (licensee of US FedEx in Mauritania): 2018 to present
                    </li>
                    <li>The Co-founder and CEO of United Services/Mauritania: 2017 to present</li>
                    <li>The Founder & Managing Director of RIM Access Group: 2016 to Present</li>
                </ul>
            </p>
        </div>
    },
    {
        name: "Mohamed Lemine ELVIL",
        position: "Co-Founder, General Manager",
        image: elvil,
        desc: <div>
            <p>Mr. Elvil is the co-founder of United Services and other companies including RIM Access.</p>
            <p>Mr. Mohamed Lemine Elvil completed 2 years in Civil Engineering at The Polytechnic School of Amman,
                Jordan.</p>
            <p>Over the last 15 years, General Manager Elvil has extensively worked in mine and water-wells drilling
                fields working with GIE-Actif, one of the leading water-wells drilling companies in Mauritania. Mr.
                Elvil’s rich experience covers work placements and trainings on solar powered (water) pumping systems in
                Portugal. His experience also includes extended practical work experience in renewable energy
                sources. </p>
        </div>
    },
    {
        name: "Fatma E. KHALIFA",
        position: "Operations Manager",
        image: fatma,
        desc: <div>
            <p>Ms Khalifa pursued her engineering studies at the prestigious Mauritanian Mining School and at the
                National Polytechnic of Nouakchott. Ms Khalifa graduated in 2017 with a Mineral Resources Engineer
                degree; she has, since then, played a pioneer role in the growing mining industry, especially in the
                research component with both Mauritanian Copper Mining and Tasiast Gold mining (Kinross) companies. </p>
            <p>Prior to joining United Services, Eng. Khalifa worked as a Technical Applications Engineer with MAXAM
                Mauritania at Kinross Tasiast site.</p>
            <p>Operations Manager Fatma Khalifa is also very well versed in technical and computer based
                applications.</p>
        </div>
    },
    {
        name: "Mahmoud BA",
        position: "Technical Advisor",
        image: ba,
        desc: <div>
            <p>Mr. Ba holds an Engineering degree in mining (specialized in blast and drilling techniques) from Vercovia
                Academy in Poland (1986). Engineer Ba has some 30 years experience on the job. He took part in work
                placements and trainings in Sweden, Norway, France, and Senegal. </p>
            <p>Mr. Ba’s extensive professional experience includes key job responsibilities in the National Industrial
                Mining Company (SNIM) since the 1990’s to this date. This impressive wealth of experience makes Mr.
                Mahmoud Ba one of our best assets in a field that is significantly expanding and where competition will
                increasingly become tougher.</p>
        </div>
    },
    {
        name: "Mohamed Abdellahi DAHI",
        position: "Senior Accounting Analyst",
        image: dahi,
        desc: <div>
            <p>Mr. Dahi holds a Master Degree in Finance Audit from the prestigious University of Hampaté Bah of Dakar
                in Senegal. Mr. Dahi has an accrued professional experience of several years in the field. He has
                extensively worked with a large number of national banks and companies on their finance auditing.</p>
            <p>As a senior auditor, Mr. Dahi successfully took part in the financial settlement of a number of local
                banks and companies over the past few years. The various companies of auditors he worked for include NET
                Audit and ExaCompte.</p>
            <p>Mr. Dahi fluently speaks French and Arabic and has a functional base in English. He masters a good range
                of computer applications that are essential in the smooth handling of his work at United Services.</p>
        </div>
    },
    {
        name: "Mohamed Laghdaf HANENA",
        position: "Senior Field Technician",
        image: hanena,
        desc: <div>
            <p>Mr. Hanena has a multi-faceted experience that covers coordinating and supervising jobs in Energy power
                stations, drilling, security and logistics. In 2018, Mr. Hanena participated in an intensive training on
                drilling tools at Drillco main plant in Santiago, Chile. This training, along with his solid technical
                experiences with First Quantum, Gryphon, and Strikos Power, has contributed to better prepare him to
                handle and supervise United Services field work. </p>
            <p>Mohamed Hanena holds a BA in English Language and Literature from the English Department at the
                University of Nouakchott. In addition to Arabic, his first language, Mohamed also speaks French and
                Spanish_ which reveals a good gift for language learning and strong communication skills.</p>
        </div>
    },
]

export default class MOT extends React.Component {

    render() {

        return <div className={"about"} dir={this.props.lang === "ar" ? "rtl" : "ltr"}>
            <Helmet>
                <title>{meta[this.props.lang].about.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].about.description}
                />
            </Helmet>

            <BigTitle title={"headers.meet_our_team"}/>
            <div className={"r bg-white"}>
                {staff.map((stafaye, i) => {
                    return <div className="c-1-1" key={i}>
                        <div className="mot">
                            <div className="img" style={{background: 'url(' + stafaye.image + ')'}}></div>
                            <div className="text">
                                <h3>{stafaye.name}</h3>
                                <span className="position">{stafaye.position}</span>
                                {stafaye.desc}

                            </div>
                        </div>
                    </div>
                })
                }
            </div>
        </div>
    }

}
