export const API_URL = process.env.NODE_ENV !== 'production' ? "http://localhost:8000" : "https://unitedmr.com/api";

export const product_categories = [
    {id: "bits", name: "DTH BITS", sub: []},
    {id: "hammers", name: "DTH HAMMERS", sub: []},
    {id: "tricons", name: "TRICONS", sub: []},
    {id: "drill_pipes", name: "DRILL PIPES", sub: []},
    {id: "lubricants_and_fuels", name: "LUBRICANTS & FUELS", sub: []},
    {id: "augers_and_cutters", name: "AUGERS & CUTTERS", sub: []},
    {id: "others", name: "OTHERS", sub: []},
];