import React from 'react';
import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";
import ReactTable from "react-table";

import "react-table/react-table.css";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import {Link} from "react-router-dom";
import {API_URL, product_categories} from "../../Static";
import Editor from "../Editor";


const meta = require("../../translations/meta.json");


export default class AdminProducts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            valid: false,
            success: false,
            error: false,
            loading: false,
            products: '',
            product: {
                id: '',
                category: "bits",
                short_description: "",
                description: "",
                image: "",
                imagetype: "",
                label: "",
                attachments: []
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.uploadFi = this.uploadFi.bind(this)
        this.handleHtmlChange = this.handleHtmlChange.bind(this)
        this.getBase64 = this.getBase64.bind(this)
        this.save = this.save.bind(this)
        this.getProducts = this.getProducts.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.checkValid = this.checkValid.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
    }

    checkValid() {
        return this.state.product.category && this.state.product.short_description && this.state.product.description && this.state.product.image && this.state.product.label;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const oldproductIdid = this.props.match.params.productId;
        const nextproductIdid = nextProps.match.params.productId;
        if (oldproductIdid === nextproductIdid) return;
        try {
            fetch(API_URL + "/api/products/" + atob(nextproductIdid))
                .then(response => response.json())
                .then(product => {
                    const files = JSON.parse(product.attachments);
                    this.setState({files, product})
                })
                .catch(error => {
                    // // console.log("error", error);
                });
        } catch (e) {
            // console.log(e)
        }

    }

    componentWillMount() {
        this.getProducts();
        let productId = '';
        try {
            productId = atob(this.props.match.params.productId);
        } catch (e) {
            this.setState({
                product: {
                    id: '',
                    category: "bits",
                    short_description: "",
                    description: "",
                    image: "",
                    imagetype: "",
                    label: "",
                    attachments: []
                }
            });
            return;
        }

        fetch(API_URL + "/api/products/" + productId)
            .then(response => response.json())
            .then(product => {
                const files = JSON.parse(product.attachments);
                this.setState({files, product})
            })
            .catch(error => {
                // // console.log("error", error);
            });

    }


    deleteImage() {
        const product = this.state.product;
        product.image = '';
        product.imagetype = '';
        this.setState({product})
    }

    getBase64(file, cb) {
        if (!file) {
            const product = this.state.product;
            product.image = '';
            product.imagetype = '';
            this.setState({product})
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            // console.log('Error: ', error);
        };
    }

    uploadFile(e) {
        const product = this.state.product;
        if (!e.target.files || e.target.files.length === 0) {
            product.image = ''
            this.setState({product});
        } else {
            this.getBase64(e.target.files[0], (fileBase64) => {
                const product = this.state.product;
                product.image = fileBase64.split(",")[1];
                product.imagetype = fileBase64.split(",")[0].split(";")[0].split(":")[1];
                this.setState(product, () => {
                    console.error(product)
                })
            });
        }


        this.getBase64(e.target.files[0], (fileBase64) => {
            const product = this.state.product;
            product.image = fileBase64.split(",")[1];
            product.imagetype = fileBase64.split(",")[0].split(";")[0].split(":")[1];
            this.setState(product, () => {
                console.error(product)
            })
        });
    }

    getProducts() {
        fetch(API_URL + "/api/allproducts", {
            method: 'get', headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((resp) => resp.json())
            .then(products => {
                if (!products.error) {
                    this.setState({
                        products
                    })
                }
            })
            .catch(error => {
                // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });
    }

    save(e) {
        e.preventDefault();
        if (!this.state.valid) return;

        this.setState({
            loading: true
        });
        const finalProduct = this.state.product;
        finalProduct.attachments = JSON.stringify(this.state.files);
        fetch(
            this.state.product.id ?
                (API_URL + "/api/products/" + this.state.product.id)
                : API_URL + "/api/products", {
                method: this.state.product.id ? 'PUT' : 'POST',
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    finalProduct
                )
            }).then(response => response.json())
            .then((product) => {
                if (!this.state.product.id) {
                    this.setState({
                        success: true,
                        loading: false,
                        files: [],
                        product: {
                            id: '',
                            category: "bits",
                            short_description: "",
                            description: "",
                            image: "",
                            imagetype: "",
                            label: "",
                            attachments: []
                        },
                        valid: this.checkValid()
                    })
                } else {
                    this.setState({
                        product: product,
                        success: true,
                        loading: false
                    }, () => {
                        // console.log("saved", product);
                    })
                }
            }).catch((error) => {
            this.setState({
                error: true,
                loading: false
            })
                .catch(error => {
                    // // console.log("error", error);
                });
        }).finally(() => {
            this.getProducts();
        })
    }


    handleInputChange(e) {
        let product = this.state.product;
        const field = e.target.name;
        product[field] = e.target.value;
        this.setState({product}, () => {
            // // console.log(this.state.product);
        });
        this.setState({
            success: false,
            error: false,
            loading: false,
            valid: this.checkValid()
        });
    }

    deleteProduct(product) {
        confirmAlert({
            title: "Destructive action",
            message: "Are sure you want to delete product :" + product.label,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        fetch(API_URL + "/api/products/" + product.id, {
                            method: 'delete', headers: {
                                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(res => {
                                // console.log(res);
                            })
                            .catch(error => {
                                console.error(error);
                            }).finally(() => {
                            this.getProducts();
                        })
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }


    render() {

        return <div className={"admin"}>

            <Helmet>
                <title>United Services | Products management</title>
            </Helmet>


            <BigTitle title={this.state.product.id ? "Edit Product" : "Create new product"}/>

            <div className={"r status"}>
                {!this.state.success && !this.state.valid && <div className="c-1-1 valid">Please fill the blanks</div>}
                {this.state.loading && <div className="c-1-1 loading">Saving...</div>}
                {!this.state.loading && this.state.error &&
                <div className="c-1-1 error">Error while saving product, retry.</div>}
                {!this.state.loading && this.state.success && <div className="c-1-1 success">Product saved</div>}
            </div>
            {!this.state.loading && <form onSubmit={this.save}>
                <div className={"r admin-panel"}>
                    <div className={'c-1-3 left-2 '}>
                        <button disabled={!this.state.valid} className={"admin-button"} onClick={this.save}>Save
                        </button>
                    </div>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Product Label</h4>
                        <input placeholder={"Label"} type={"text"} name={"label"} value={this.state.product.label}
                               onChange={this.handleInputChange}/>
                    </div>

                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Product image</h4>
                        {this.state.product.image &&
                        <div className={"product-image-actions actions"}>
                            <i className={"delete"} onClick={this.deleteImage}><span>delete image</span></i>
                        </div>}

                        {this.state.product.image &&
                        <img style={{border: "1px solid #aaa", maxWidth: 250}} alt={this.state.product.title}
                             src={"data:" + this.state.product.imagetype + ";base64," + this.state.product.image}/>}
                    </div>

                    <div className={"c-1-1"}>
                        <input placeholder={"Image"} type={"file"} name={"image"}
                               onChange={this.uploadFile}/>
                    </div>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Short description</h4>
                        <i style={{
                            padding: "0 10px",
                            fontSize: 16,
                        }}>Short description lines must be separated by double semi-colon <b>;;</b></i>
                        <input placeholder={"Short description"} type={"text"} name={"short_description"}
                               value={this.state.product.short_description}
                               onChange={this.handleInputChange}/>
                    </div>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Product category</h4>
                        <select value={this.state.product.category} name={"category"} onChange={this.handleInputChange}>
                            {product_categories.map((cat, i) => {
                                return <option value={cat.id} key={i}>
                                    {cat.name}
                                </option>
                            })
                            }</select>
                    </div>
                </div>
                <div className={"r"}>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Product description</h4>
                        <Editor placeholder={'Write something...'}
                                editorHtml={this.state.product.description}
                                handleChange={this.handleHtmlChange}
                        />
                    </div>
                </div>
                <div className={"r"}>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Attachments</h4>
                        {this.state.files && <ul>
                            {this.state.files.map((file, i) => {
                                return <li key={i}>
                                    <span>{file.originalName}</span>
                                    <a download target={"_blank"} href={API_URL + "/api/viewfile/" + file.name}
                                       style={{
                                           margin: "0 10px",
                                           padding: '1px 5px',
                                           border: 'none',
                                           cursor: 'pointer',
                                           borderRadius: 5,
                                           background: 'green',
                                           color: 'white',
                                           fontSize: 12,
                                       }}
                                    >preview</a>
                                    <i
                                        style={{
                                            margin: "0 10px",
                                            padding: '1px 5px',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: 5,
                                            background: 'red',
                                            color: 'white',
                                            fontSize: 12,
                                        }}
                                        onClick={() => {
                                            const files = this.state.files;
                                            files.splice(i, 1);
                                            const product = this.state.product;
                                            product.attachments = files;
                                            this.setState({files, product})

                                            fetch(API_URL + '/api/deletefile/' + file.name, {
                                                method: 'DELETE'
                                            })
                                                .catch(error => {
                                                    // // console.log("error", error);
                                                });
                                        }
                                        }
                                    >delete</i>
                                </li>
                            })
                            }
                        </ul>}
                        <input multiple type={"file"} name={"file"} onChange={this.uploadFi}/>
                    </div>
                </div>
            </form>}

            {this.state.products && <BigTitle title={"Products"}/>}
            {this.state.products && <ReactTable
                data={this.state.products}
                defaultPageSize={5}
                style={{
                    height: "400px"
                }}
                columns={[
                    {
                        Header: "Label",
                        accessor: "label",
                        minWidth: 200
                    },
                    {
                        Header: "Category",
                        accessor: "category",
                        minWidth: 100
                    },
                    {
                        accessor: '',
                        Cell: ({original}) => {
                            return original.short_description.split(";;").join(", ");
                        },
                        Header: "Description"
                    },
                    {
                        accessor: '',
                        Cell: ({original}) => {
                            return (
                                <div>
                                    <Link to={"/admin/products/" + btoa(original.id)}
                                          style={{marginRight: 5, cursor: "pointer"}}>Edit</Link>
                                    <a onClick={this.deleteProduct.bind(this, original)}
                                       style={{color: "red", cursor: "pointer"}}>Delete</a></div>
                            )
                        },
                        Header: "Actions"
                    }
                ]}
            />}

        </div>
    }

    handleHtmlChange(html) {
        const product = this.state.product;
        product.description = html;
        this.setState({product, valid: this.checkValid()})

        this.setState({
            success: false,
            error: false,
            loading: false
        });
    }


    uploadFi(e) {
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            const data = new FormData()
            data.append('file', file)

            fetch(API_URL + '/api/file', {
                method: 'POST',
                body: data
            }).then(response => response.json())
                .then(filename => {
                    const files = this.state.files;
                    files.push({name: filename, originalName: file.name});
                    const product = this.state.product;
                    product.attachments = files;
                    this.setState({product, files})
                })
                .catch(error => {
                    // // console.log("error", error);
                })
                .catch(error => {
                    // // console.log("error", error);
                });
        }
    }
}

