/**
 * Created by moolsbytheway on 8/17/17.
 */

import React from "react";

export default function CarouselItem(props) {

    return (
        <div className="r carousel-item">
            <div className="c-1-3 right-2">
                <div className={"carousel-item-content black"}>
                    <h1>{props.text}</h1>
                </div>
            </div>
        </div>
    )
}