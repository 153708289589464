import React from "react";
import {FormattedMessage} from "react-intl";
import nf from "../assets/img/notfound.png"

export default class NF404 extends React.Component {
    render() {
        return <div className={"r"} style={{textAlign: "center"}}>
            <div className={"c-1-1"}>
                <img style={{
                    maxWidth: "80%",
                    "paddingTop": 50
                }} src={nf}/>
                <h3 className={"font-raleway"}><FormattedMessage id={"app.notFound"}/></h3>
            </div>
        </div>
    }
}
