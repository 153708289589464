import React from 'react';
import BigTitle from "../BigTitle";
import staff1 from "../../assets/img/about/1.jpg";
import staff2 from "../../assets/img/about/2.jpg";
import safety from "../../assets/img/safety.png";
import who_we_are from "../../assets/img/who_we_are.png";
import about from "../../assets/img/about.jpg";
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";


const meta = require("../../translations/meta.json");


export default class About extends React.Component {

    render() {

        return <div className={"about"} dir={this.props.lang === "ar" ? "rtl" : "ltr"}>
            <Helmet>
                <title>{meta[this.props.lang].about.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].about.description}
                />
            </Helmet>

            <div className={"bg-white bg-top"}><img src={about} width={"100%"}/></div>
            <div className={"r bg-white"}>
                <div className={"c-1-1"}>
                    <BigTitle icon={who_we_are} title={"menu.who_we_are"}/></div>
                <div className={"c-1-1 "}>
                    <p><FormattedMessage id={"about.p1"}/></p>
                    <p><FormattedMessage id={"about.p2"}/></p>
                    <p><FormattedMessage id={"about.p3"}/></p>
                </div>
                <div className={"c-1-1 staff"}>
                    <img src={staff1} alt={"staff"}/></div>

            </div>
            <br/>
            <div className={"r bg-white"}>
                <div className={"c-1-1"}>
                    <BigTitle icon={safety} title={"headers.safety"}/></div>
                <div className={"c-1-1"}>
                    <p><FormattedMessage id={"about.p4"}/></p></div>
                <div className={"c-1-1 staff"}>
                    <img width={"100%"} src={staff2} alt={"staff"}/></div>
            </div>
        </div>
    }

}
