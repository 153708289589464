import React, {createContext} from 'react';

import logo_image from "../assets/img/logo.jpg";
import * as ReactDOM from "react-dom";
import {Link} from "react-router-dom";
import MaterialIcon from "./MaterialIcon";
import {FormattedMessage} from "react-intl"

const menu_items = [
    {id: "home", name: <FormattedMessage id={"menu.home"}/>, sub: []},
    {
        id: "admin", name: "admin",
        sub: [
            {id: "users", name: <FormattedMessage id={"menu.users"}/>, sub: [], require_admin: true},
            {id: "posts", name: <FormattedMessage id={"menu.posts"}/>, sub: []},
            {id: "products", name: <FormattedMessage id={"menu.products"}/>, sub: []}
        ]
    }, {
        id: "about", name: <FormattedMessage id={"menu.about_us"}/>,
        sub: [
            {id: "who_we_are", name: <FormattedMessage id={"menu.who_we_are"}/>, sub: []},
            {id: "who_we_work_with", name: <FormattedMessage id={"menu.who_we_work_with"}/>, sub: []},
            {id: "meet_our_team", name: <FormattedMessage id={"menu.meet_our_team"}/>, sub: []}
        ]
    },
    {
        id: "what_we_do", name: <FormattedMessage id={"menu.what_we_do"}/>, sub: [
            {
                id: "products", name: <FormattedMessage id={"menu.products"}/>,
                sub: [
                    {id: "bits", name: "DTH BITS", sub: []},
                    {id: "hammers", name: "DTH HAMMERS", sub: []},
                    {id: "tricons", name: "TRICONS", sub: []},
                    {id: "drill_pipes", name: "DRILL PIPES", sub: []},
                    {id: "lubricants_and_fuels", name: "LUBRICANTS & FUELS", sub: []},
                    {id: "augers_and_cutters", name: "AUGERS & CUTTERS", sub: []},
                    {id: "others", name: "OTHERS", sub: []},
                ]
            },
            {id: "services", name: <FormattedMessage id={"menu.services"}/>, sub: []}
        ]
    },  /*{
        id: "applications", name: "applications",
        sub: [
            {id: "mining", name: "mining", sub: []},
            {id: "water_wells", name: "waterwells", sub: []},
            {id: "civil_engineering", name: "civil engineering", sub: []},
            {id: "oil_and_gas", name: "oil and gas", sub: []},
        ]
    },*/
    {id: "blog", name: <FormattedMessage id={"menu.gallery"}/>, sub: []},

    {id: "contact", name: <FormattedMessage id={"menu.contact_us"}/>, sub: []}
];


class MenuItem extends React.Component {
    constructor() {
        super();
        this.state = {
            leftPos: 0,
            width: 0
        };

        this.setSelectedMenu = this._setSelectedMenu.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        // if forceUpdate changes value that means that the language is changed or the window is resized
        // so if that's true then we update the menu tracker position wuth the current one
        // we ad a 300 ms delay to the update to let the new values of menu to render if languagee has changed

        if (nextProps.forceUpdate !== this.props.forceUpdate) {
            let rect = ReactDOM.findDOMNode(this);
            setTimeout(() => {
                this.setState({
                    leftPos: rect.offsetLeft,
                    width: rect.offsetWidth
                });
            }, 300)

        }
    }

    _setSelectedMenu(e) {
        e.stopPropagation();
        if (e.target.children.length > 0) this.props.history.push(e.target.children[0].pathname);
        this.props.setSelectedMenu(this.props.menu_item.id, "");
    }

    componentDidMount() {
        let rect = ReactDOM.findDOMNode(this);
        this.setState({
            leftPos: rect.offsetLeft,
            width: rect.offsetWidth
        });

    }


    render() {
        let menu_item = this.props.menu_item;
        let link = menu_item.id === "home" ? "/" : "/" + menu_item.id;

        const after_sub_style = {
            width: this.state.width,
            left: this.state.leftPos
        };

        return <li style={{color: menu_item.id === 'admin' ? '#b36b00' : 'inherit'}}
                   className={this.props.selectedMenu.startsWith(menu_item.id) ? "active" : ""}
                   onClick={this.setSelectedMenu}>
            <Link to={link}>{menu_item.name}</Link>

            <span className={"after_cursor not_sub"} style={after_sub_style}/>
            {menu_item.sub.length > 0 && <ul className={"sub_menu "}>
                {menu_item.sub.map((sub_item, j) => {
                    return <SubMenuItem key={j}
                                        history={this.props.history}
                                        setSelectedMenu={this.props.setSelectedMenu}
                                        id={menu_item.id}
                                        menu_item={sub_item}
                                        selectedSubMenu={this.props.selectedSubMenu}
                    />
                })}
            </ul>}
        </li>
    }
}

class SubMenuItem extends React.Component {
    constructor() {
        super();
        this.state = {
            topPos: 0,
            height: 0
        };

        this.setSelectedMenu = this._setSelectedMenu.bind(this);

    }

    _setSelectedMenu(e) {
        e.stopPropagation();
        if (e.target.children.length > 0) this.props.history.push(e.target.children[0].pathname);
        this.props.setSelectedMenu(this.props.id, this.props.menu_item.id);
    }

    componentDidMount() {
        if (!ReactDOM.findDOMNode(this)) return;
        let rect = ReactDOM.findDOMNode(this);
        this.setState({
            topPos: rect.offsetTop,
            height: rect.offsetHeight
        });

    }


    render() {
        let menu_item = this.props.menu_item;
        let link = "/" + this.props.id + "/" + menu_item.id;

        const after_sub_style =
            {
                height: this.state.height,
                top: this.state.topPos,
                width: 5
            };

        return this.ShouldShow() ? <li className={this.props.selectedSubMenu === menu_item.id ? "active" : ""}
                                       onClick={this.setSelectedMenu}>
            <Link to={link}>{menu_item.name}</Link>
            <span className={"after_cursor is_sub"} style={after_sub_style}/>
        </li> : null;
    }

    ShouldShow() {
        let user = sessionStorage.getItem("user");
        if (!user || (user === "undefined" || user.trim() === "")) return {};

        user = JSON.parse(sessionStorage.getItem("user"));
        return this.props.menu_item.require_admin ? user.is_admin : true;
    }
}

export const MenuContext = createContext();

export class Menu extends React.Component {

    render() {
        return <MenuContext.Consumer>
            {({selectedMenu, selectedSubMenu, fixIt, is_menu_active, is_mobile, loggedin}) => (
                <div
                    className={(fixIt || is_mobile) ?
                        (is_menu_active ? "menu fixed menu_active" : "menu fixed")
                        : "menu"}>
                    <div className={"menu-holder"}>
                        <div className={"logo"}>
                            <Link to={"/"}><img alt="logo" src={logo_image}/></Link>
                        </div>
                        <div id="ham" className={is_menu_active ? "ham active" : "ham"}
                             onClick={this.props.activateMenu}>
                            <MaterialIcon icon={is_menu_active ? "close" : "menu"}/>
                        </div>
                        <div className={"menu-items"}>
                            <ul className={" bold"}>
                                {menu_items.map((item, i) => {
                                    return (
                                        item.id === 'admin' ?
                                            (loggedin ? <MenuItem key={i}
                                                                  forceUpdate={this.props.forceUpdate}
                                                                  history={this.props.history}
                                                                  setSelectedMenu={this.props.setSelectedMenu}
                                                                  menu_item={item}
                                                                  selectedMenu={selectedMenu}
                                                                  selectedSubMenu={selectedSubMenu}
                                                />
                                                : <span key={i} className={"hidden_span"}/>)
                                            : <MenuItem key={i}
                                                        forceUpdate={this.props.forceUpdate}
                                                        history={this.props.history}
                                                        setSelectedMenu={this.props.setSelectedMenu}
                                                        menu_item={item}
                                                        selectedMenu={selectedMenu}
                                                        selectedSubMenu={selectedSubMenu}
                                            />)
                                })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </MenuContext.Consumer>
    }
}
