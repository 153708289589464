import React from 'react';
import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";
import ReactTable from "react-table";

import "react-table/react-table.css";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import {API_URL} from "../../Static";


const meta = require("../../translations/meta.json");

export default class AdminUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            postTitle: '',
            valid: false,
            postImage: '',
            editorHtml: '',
            success: false,
            error: '',
            loading: false,
            users: '',
            user: {
                name: '',
                email: '',
                password: '',
                is_admin: false
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.save = this.save.bind(this)
        this.getUsers = this.getUsers.bind(this)
    }

    componentWillMount() {
        this.getUsers();
    }

    getUsers() {
        fetch(API_URL + "/api/users", {
            method: 'get', headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((resp) => resp.json())
            .then(users => {
                if (!users.error) {
                    users = users.filter(user => {
                        return user.id !== this.props.userid;
                    });
                    this.setState({
                        users
                    })
                }
            })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });
    }

    save(e) {
        e.preventDefault();
        if (!this.state.valid) return;

        this.setState({
            loading: true
        })
        fetch(API_URL + "/api/user", {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                this.state.user
            )
        }).then(response => response.json())
            .then((res) => {
                if (!res.id) {
                    let errorMsg = '';
                    if (res.password) {
                        errorMsg = errorMsg.concat(res.password[0])
                    }
                    if (res.email) {
                        if (res.password) errorMsg = errorMsg.concat(", ");
                        errorMsg = errorMsg.concat(res.email[0])
                    }

                    this.setState({
                        error: errorMsg,
                        loading: false
                    })
                    return;
                }
                this.setState({
                    success: true,
                    loading: false,
                    user: {
                        name: '',
                        email: '',
                        password: '',
                        is_admin: false
                    },
                    valid: false

                })
            }).catch((error) => {
            this.setState({
                error: "Error while saving post, retry.",
                loading: false
            })
        })
            .catch(error => {
                // // console.log("error", error);
            }).finally(() => {
            this.getUsers();
        })
    }


    handleInputChange(e) {
        let user = this.state.user;
        const field = e.target.name;
        user[field] = e.target.name === "is_admin" ? !user.is_admin : e.target.value;
        this.setState({user}, () => {
            // // console.log(this.state.user);
        });
        this.setState({
            success: false,
            error: '',
            loading: false,
            valid: e.target.value.length
        });
    }

    deleteUser(user) {
        confirmAlert({
            title: "Destructive action",
            message: "Are sure you want to delete user :" + user.name,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        fetch(API_URL + "/api/users/" + user.id, {
                            method: 'delete', headers: {
                                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(res => {
                                // // console.log(res);
                            })
                            .catch(error => {
                                // console.error(error);
                            }).finally(() => {
                            this.getUsers();
                        })
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    render() {

        return <div className={"admin"}>

            <Helmet>
                <title>United Services | Users management</title>
            </Helmet>

            <BigTitle title={"Create new user"}/>

            <div className={"r status"}>
                {!this.state.success && !this.state.valid && <div className="c-1-1 valid">Please fill the blanks</div>}
                {this.state.loading && <div className="c-1-1 loading">Saving...</div>}
                {!this.state.loading && this.state.error &&
                <div className="c-1-1 error">{this.state.error}</div>}
                {!this.state.loading && this.state.success && <div className="c-1-1 success">User saved</div>}
            </div>
            {!this.state.loading && <form onSubmit={this.save}>
                <div className={"r admin-panel"}>
                    <div className={'c-1-3 left-2 '}>
                        <button disabled={!this.state.valid} className={"admin-button"} onClick={this.save}>Save
                        </button>
                    </div>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Name</h4>
                        <input placeholder={"Name"} type={"text"} name={"name"} value={this.state.user.name}
                               onChange={this.handleInputChange}/>
                    </div>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Email</h4>
                        <input placeholder={"E-mail"} type={"email"} name={"email"} value={this.state.user.email}
                               onChange={this.handleInputChange}/>
                    </div>
                    <div className={"c-1-1"} style={{textAlign: "left"}}>
                        <h4>Password</h4>
                        <input placeholder={"Password"} type={"password"} name={"password"}
                               value={this.state.user.password}
                               onChange={this.handleInputChange}/>
                    </div>
                    <span style={{
                        padding: "0 10px",
                        fontSize: 20
                    }}>User is admin</span>
                    <div className={"toggler"}>
                        <input id="checkbox" type={"checkbox"} className={"checkbox"} name={"is_admin"}
                               onChange={this.handleInputChange}
                        />
                        <label className={"label"} htmlFor="checkbox"/>
                    </div>
                </div>
            </form>}

            {this.state.users && <BigTitle title={"Users"}/>}
            {this.state.users && <ReactTable
                data={this.state.users}
                defaultPageSize={5}
                style={{
                    height: "400px"
                }}
                columns={[
                    {
                        Header: "Name",
                        accessor: "name",
                        minWidth: 300
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        minWidth: 300
                    },
                    {
                        accessor: "is_admin",
                        Cell: ({original}) => {
                            return (
                                <span>{original.is_admin ? "Yes" : "No"}</span>
                            );
                        },
                        Header: "Admin ?",
                        minWidth: 100
                    },
                    {
                        accessor: '',
                        Cell: ({original}) => {
                            return (
                                <a onClick={this.deleteUser.bind(this, original)}
                                   style={{color: "red", cursor: "pointer"}}>Delete</a>
                            )
                        },
                        Header: "Actions"
                    }
                ]}
            />}

        </div>
    }

}
