import React from 'react';
import renderHTML from "react-render-html";

import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";
import {API_URL} from "../../Static";
import {FormattedMessage} from "react-intl";

const meta = require("../../translations/meta.json");


export default class BlogPost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blogPost: ''
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const oldblogPostid = this.props.match.params.blogPost;
        const nextblogPostid = nextProps.match.params.blogPost;
        if (oldblogPostid === nextblogPostid) return;

        try {
            fetch(API_URL + "/api/posts/" + atob(nextblogPostid))
                .then(response => response.json())
                .then(post => {
                    this.setState({blogPost: post})
                })
                .catch(error => {
                    // // console.log("error", error);
                })
                .catch(error => {
                    // // console.log("error", error);
                });
        } catch (e) {
            // console.log(e)
        }

    }

    componentWillMount() {
        let postId = '';
        try {
            postId = atob(this.props.match.params.blogPost);
        } catch (e) {
            this.setState({
                blogPost: ''
            });
            return;
        }

        fetch(API_URL + "/api/posts/" + postId)
            .then(response => response.json())
            .then(post => {
                this.setState({blogPost: post})
            })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });

    }

    render() {
        const blogPost = this.state.blogPost;
        // console.error(blogPost);
        return <div className={"blogPost"}>

            {blogPost && <Helmet>
                <title>United Services | {blogPost.title}</title>
                <meta name="description"
                      content={blogPost.title}
                />
            </Helmet>}

            {blogPost && <div className={"r"}>
                <div className={"c-1-1"}>
                    <BigTitle title={blogPost.title}/>
                    <div className="rendered-html ql-editor"> {renderHTML(blogPost.text)}</div>
                </div>
            </div>}
            {!blogPost && <h2><FormattedMessage id={"app.loading"}/></h2>}
        </div>
    }

}
