import React from 'react';
import {Helmet} from "react-helmet";
import Applications from "./Applications";
import Gallery from "./Gallery";
import our_vision from "../../assets/img/our-vision.png";
import our_mission from "../../assets/img/mission.png";
import BigTitle from "../BigTitle";
import {FormattedMessage} from "react-intl";

const meta = require("../../translations/meta.json");

export default class Home extends React.Component {
    render() {
        return <div className={"home"}>


            <div className={"r"} style={{marginTop: 50}}>
                <div className={"c-1-1"}>
                    <BigTitle icon={our_vision} title={"about.vision"}/>
                    <p><FormattedMessage id={"about.vision.p1"}/></p></div>
                <div id="mission" className={"c-1-1  "}>
                    <BigTitle icon={our_mission} title={"about.mission_statement"}/>
                    <p><FormattedMessage id={"about.mission_statement.p1"}/></p>
                    <ul>
                        <li><FormattedMessage id={"about.mission.1"}/></li>
                        <li><FormattedMessage id={"about.mission.2"}/></li>
                        <li><FormattedMessage id={"about.mission.3"}/></li>
                    </ul>
                </div>
            </div>


            <Applications lang={this.props.lang}/>
            <Gallery isLoggedin={this.props.isLoggedin} isHome={true}/>
            <Helmet>
                <title>{meta[this.props.lang].home.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].home.description}
                />
            </Helmet>
        </div>
    }
}
