import React from 'react';
import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {API_URL} from "../../Static";
import {FormattedMessage} from "react-intl";


const meta = require("../../translations/meta.json");

export class Card extends React.Component {
    constructor(props) {
        super(props)
        this.delt = this.delt.bind(this)
    }

    delt() {
        this.props.delt(this.props.item);
    }

    render() {
        const item = this.props.item;
        return <div className={"card"}>
            {this.props.isLoggedin && <div className={"actions"}>
                <i><Link to={"/admin/posts/" + btoa(item.id)}><FormattedMessage id={"products.edit"}/></Link></i>
                <i className={"delete"} onClick={this.delt}><FormattedMessage id={"products.delete"}/></i>
            </div>}
            <Link to={"/blog/" + btoa(item.id)}>
                <div className="img" style={{backgroundImage: "url(" + item.image + ")"}}/>
                <h3>{item.title}</h3>
            </Link>
        </div>
    }
}

export default class Gallery extends React.Component {
    constructor(props) {
        super(props)
        this.state = {posts: [], limit: 28, page: 1}
        this.getPosts = this._getPosts.bind(this)
        this.delt = this.delt.bind(this)
    }

    componentDidMount() {
        if (this.props.isHome) {
            this.setState({
                limit: 4,
                page: 1
            }, this.getPosts);
        } else {
            this.getPosts();
        }
    }


    _getPosts() {
        fetch(API_URL + "/api/posts?page=" + this.state.page + "&limit=" + this.state.limit)
            .then(response => response.json())
            .then(res => {
                let posts = res.data;
                for (let post of posts) {
                    if (!post.image) {
                        const div = document.createElement('div');
                        div.innerHTML = post.text;
                        const images = div.getElementsByTagName('img');
                        // // console.log("images: " + images);
                        if (images && images.length) {
                            post.image = images[0].getAttribute("src");
                            // console.error(post.image);
                        }
                    }
                }
                this.setState({posts})
            })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });
    }


    delt(post) {
        confirmAlert({
            title: <FormattedMessage id={"delete.confirm.title"}/>,
            message: <FormattedMessage id={"delete.confirm.body"}/> + " :" + post.title,
            buttons: [
                {
                    label: <FormattedMessage id={"general.yes"}/>,
                    onClick: () => {
                        fetch(API_URL + "/api/posts/" + post.id, {
                            method: 'delete', headers: {
                                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(res => {
                                // // console.log(res);
                            })
                            .catch(error => {
                                // console.error(error);
                            }).finally(() => {
                            this.getPosts();
                        })
                    }
                },
                {
                    label: <FormattedMessage id={"general.no"}/>
                }
            ]
        });
    }

    render() {

        return <div className={"home"}>

            {!this.props.isHome && <Helmet>
                <title>{meta[this.props.lang].gallery.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].gallery.description}
                />
            </Helmet>}
            {this.props.isHome && this.state.posts.length >= 4 && <BigTitle title={"headers.gallery"}/>}
            {this.props.isHome && this.state.posts.length >= 4 && <div className={"r"}>
                {this.state.posts.map((item, i) => {
                    return <div key={i} className={"c-1-4"}>
                        <Card
                            isLoggedin={this.props.isLoggedin}
                            delt={this.delt}
                            item={item}/>
                    </div>
                })}
            </div>}

            {!this.props.isHome && <BigTitle title={"headers.gallery"}/>}
            {!this.props.isHome && !this.state.posts.length && <h2><FormattedMessage id={"app.loading"}/></h2>}
            {!this.props.isHome && this.state.posts && <div className={"r"}>
                {this.state.posts.map((item, i) => {
                    return <div key={i} className={"c-1-4"}>
                        <Card
                            isLoggedin={this.props.isLoggedin}
                            delt={this.delt}
                            item={item}/>
                    </div>
                })}
            </div>}
        </div>
    }

}
