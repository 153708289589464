import React from 'react';
import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";


import usmrbf from '../../assets/img/partners/usmrbf.png';
import unpm from '../../assets/img/partners/unpm.png';
import pdac from '../../assets/img/partners/pdac.png';
import cciam from '../../assets/img/partners/cciam.png';
import drillco from '../../assets/img/partners/drillco.png';
import sundril from '../../assets/img/partners/sundril.png';
import national_express from '../../assets/img/partners/national-express.png';
import rockoil from '../../assets/img/partners/rock_oil.png';
import mts from '../../assets/img/partners/mts.png';
import kinross from '../../assets/img/partners/kinross-logo.svg';

const meta = require("../../translations/meta.json");


export default class WWWW extends React.Component {

    render() {

        return <div className={"about"} dir={this.props.lang === "ar" ? "rtl" : "ltr"}>
            <Helmet>
                <title>{meta[this.props.lang].about.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].about.description}
                />
            </Helmet>

            <BigTitle title={"headers.who_we_work_with"}/>
            <div className={"r bg-white"}>

                <div className={"c-1-1"}>
                    <BigTitle title={"headers.clients"}/>
                    <div className={"r partners memberships"}>
                        <div className={"c-1-1"} title={"Kinross"}>
                            <img alt={"Kinross"} src={kinross}/>
                        </div>
                    </div>
                </div>
                <div className={"c-1-1"}>
                    <BigTitle title={"headers.partners"}/>
                    <div className={"r partners memberships"}>
                        <div className={"c-1-1"} title={"Drillco"}>
                            <img alt={"Drillco"} src={drillco}/>
                        </div>
                        <div className={"c-1-2"} title={"MTS Perforator"}>
                            <img alt={"MTS Perforator"} src={mts}/>
                        </div>
                        <div className={"c-1-2"} title={"RockOil"}>
                            <img alt={"RockOil"} src={rockoil}/>
                        </div>
                        <div className={"c-1-2"} title={"Sundril"}>
                            <img alt={"Sundril"} src={sundril}/>
                        </div>
                        <div className={"c-1-2"} title={"National express"}>
                            <img alt={"National express"} src={national_express}/>
                        </div>
                    </div>
                </div>
                <div className={"c-1-1"}>

                    <BigTitle title={"headers.memberships"}/>
                    <div className={"r memberships"}>
                        <div className={"c-1-2"} title={"Prospectors and Developers Association of Canada"}>
                            <img alt={"Prospectors and Developers Association of Canada"} src={pdac}/>
                        </div>
                        <div className={"c-1-2"} title={"Chamber of Commerce, Industry and Agriculture of Mauritania"}>
                            <img alt={"Chamber of Commerce, Industry and Agriculture of Mauritania"} src={cciam}/>
                        </div>
                        <div className={"c-1-2"} title={"National Union of Mauritanian Employers"}>
                            <img alt={"National Union of Mauritanian Employers"} src={unpm}/>
                        </div>
                        <div className={"c-1-2"} title={"United States-Mauritania Business Forum"}>
                            <img alt={"United States-Mauritania Business Forum"} src={usmrbf}/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }

}
