import React from "react";
import {withRouter} from "react-router-dom";

class Container extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            const pageContent = document.getElementById("page-content")
            if (pageContent.offsetTop !== undefined) {
                window.scrollTo(0, pageContent.offsetTop - 110);
            }
        }
    }

    render() {
        return <div className={this.props.font}>
            {this.props.children}
        </div>
    }
}

export default withRouter(Container);