/**
 * Created by moolsbytheway on 8/17/17.
 */
import React from "react";
import MaterialIcon from "./MaterialIcon";

export default function Error(props) {
    let content;
    let theClass;
    if (props.iconError) {
        content = <MaterialIcon icon={props.icon}/>;
        theClass = "iconError";
    } else {
        content = props.text;
        theClass = "customError";
    }
    return (
        <div onClick={props.handleErrorClick}
             className={theClass}>{content}</div>
    );
}