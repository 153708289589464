import React, {Component} from 'react';
import {BrowserRouter, Link, Redirect, Route, Switch} from 'react-router-dom';
import {FormattedMessage, IntlProvider} from "react-intl";

import messages_fr from "../translations/fr.json";
import messages_en from "../translations/en.json";


import {LanguageChooser, LanguageChooserContext} from "./LanguageChooser";
import {Menu, MenuContext} from "./Menu";
import NF404 from "./NF404";
import Footer from "./Footer";
import WebFont from "webfontloader";
import Container from "./Container";
import BlogPost from "./pages/BlogPost";
import Admin from "./admin/Admin";
import Login from "./admin/Login";
import MaterialIcon from "./MaterialIcon";
import AdminUsers from "./admin/AdminUsers";
import Carousel from "./Carousel/Carousel";
import Home from "./pages/Home";
import Mail from "./admin/Mail";
import Product from "./pages/Product";
import AdminProducts from "./admin/AdminProducts";
import Products from "./pages/Products";
import {API_URL} from "../Static";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import WWWW from "./pages/WWWW";
import MOT from "./pages/MOT";


const mapboxgl = require('mapbox-gl');

const messages = {
    'fr': messages_fr,
    'en': messages_en
};


class AppWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mails: '',
            mailActive: false,
            user: '',
            firstLoad: true,
            lang: "en",
            langChooseEnabled: false,
            font: "font-raleway",
            languageChooser: false,
            fixIt: false,
            loadingFonts: process.env.NODE_ENV === "production",
            is_mobile: false,
            menu: {
                selected: window.location.pathname.split("/")[1] === "" ? "home" : window.location.pathname.split("/")[1],
                selectedSub: "",
                menu_active: false,
                forceUpdate: false
            }
        };
        this.getMails = this._getMails.bind(this)
        this.isAuth = this._isAuth.bind(this)
        this.getToken = this._getToken.bind(this)
        this.login = this._login.bind(this)
        this.logout = this._logout.bind(this)
        this.activateMenu = this._activateMenu.bind(this);
        this.changeLanguage = this._changeLanguage.bind(this);
        this.toggleChooser = this._toggleChooser.bind(this);
        this.setSelectedMenu = this._setSelectedMenu.bind(this);
        this.handleScroll = this._handleScroll.bind(this);
        this.handleResize = this._handleResize.bind(this);
        this.updateMails = this._updateMails.bind(this);
        this.archiveMails = this._archiveMails.bind(this);
    }

    _getMails() {
        fetch(API_URL + "/api/allmails",
            {
                method: 'get', headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(response => response.json())
            .then(mails => {
                this.setState({mails})
            })
            .catch(error => {
                // // console.log("error", error);
            }).catch(err => {
            console.error(err);
        })
    }

    _getToken() {
        return sessionStorage.getItem("api_key");
    }

    _isAuth() {
        const user = sessionStorage.getItem("user");
        if (!user || (user === "undefined" || user.trim() === "")) return "";
        return JSON.parse(sessionStorage.getItem("user"));
    }

    _activateMenu() {
        let menu = this.state.menu;

        const doc = document.getElementById("unitedmr");
        doc.style.overflow = menu.menu_active ? "auto" : "hidden";
        menu.menu_active = !menu.menu_active;

        this.setState({menu});

    }

    _setSelectedMenu(id, sub_id) {

        let menu = this.state.menu;

        const doc = document.getElementById("unitedmr");
        if (document.getElementById("ham").style.display === "block") {
            doc.style.overflow = "hidden";
            menu.menu_active = true;
        } else {
            doc.style.overflow = "auto";
            menu.menu_active = false;
        }

        // if no parameters are passed then don't change the values
        // that means that the menu is being closed
        if (typeof id === "string" && typeof sub_id === "string") {
            menu.selected = id;
            menu.selectedSub = sub_id
        }

        this.setState({menu});

    }

    _handleScroll(e) {
        if ((window.scrollY > 150) !== this.state.fixIt) {
            this.setState({
                fixIt: window.scrollY > 150
            })
        }

    }

    _handleResize(e) {
        let menu = this.state.menu;

        const doc = document.getElementById("unitedmr");
        if (window.innerWidth > 1023) {
            doc.style.overflow = "auto";
            menu.menu_active = false;
            this.setState({
                is_mobile: false
            })
        } else {
            this.setState({
                is_mobile: true
            })
        }
        menu.forceUpdate = !this.state.menu.forceUpdate;

        this.setState({menu});


    }

    componentWillMount() {
        localStorage.setItem("products", JSON.stringify({}))
        let WebFontConfig = {
            google: {
                families: ['Raleway:400,700,900', 'sans-serif']
            },
            custom: {
                families: ['Material Icons:400'],
                urls: ['https://fonts.googleapis.com/icon?family=Material+Icons']
            },
            classes: false,
            active: () => {
                this.setState({
                    loadingFonts: false
                })
            }
        };

        WebFont.load(WebFontConfig);

        this.setState({
            user: this.isAuth(),
        });
        this.state.firstLoad && this.setState({
            firstLoad: false,
            lang: "en", // todo this.props.lang,
            font: "font-raleway" // todo this.props.lang === "ar" ? "font-elmessiri" : "font-raleway"
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);

        // Add RTL support if you want to support Arabic
        mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.0/mapbox-gl-rtl-text.js');

        this.setState({
            interval: setInterval(() => {
                if (this.state.user) this.getMails();
            }, 60000)
        });
        if (this.state.user) {
            setTimeout(() => {
                this.getMails();
            }, 5000)
        }
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        clearInterval(this.state.interval);
    }


    _changeLanguage(lang) {
        let menu = this.state.menu;
        menu.forceUpdate = !this.state.menu.forceUpdate;
        this.setState({
            lang: lang,
            font: lang === "ar" ? "font-elmessiri" : "font-raleway",
            languageChooser: false,
            menu: menu
        })

    }

    _logout(e) {
        sessionStorage.clear();
        this.setState({
            user: ''
        }, () => {
            window.location = "/";
        });
    }

    _login(credentials) {
        fetch(API_URL + "/api/login", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: credentials.email,
                password: credentials.password
            })
        }).then((resp) => resp.json())
            .then(data => {
                // // console.log("data", data);
                // if
                if (data.status === 'fail') return;
                sessionStorage.setItem("user", JSON.stringify(data.user))
                sessionStorage.setItem("api_key", data.api_key)
                this.setState({
                    user: data.user
                });
            }).finally(() => {
            window.location = "/";
        })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log(error);
            })
    }

    _toggleChooser(e) {
        this.setState({
            languageChooser: !this.state.languageChooser
        })
    }

    _updateMails(id) {
        fetch(API_URL + "/api/mail/" + id, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                read: true
            })
        }).then(data => {
            this.getMails();
        }).catch((error) => {
            console.error(error)
        })
            .catch(error => {
                // // console.log("error", error);
            });
    }

    _archiveMails(id, archived) {
        fetch(API_URL + "/api/mail/" + id, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                archived: archived === "1" ? "0" : "1"
            })
        }).then(data => {
            this.getMails();
        }).catch((error) => {
            console.error(error)
        })
            .catch(error => {
                // // console.log("error", error);
            });
    }

    render() {

        let languageChooserClassName = "languageChooser bold font-raleway ";
        languageChooserClassName += this.state.menu.menu_active ? "hide_lc" : "";

        return (

            <IntlProvider locale={this.state.lang} messages={messages[this.state.lang]}>
                <BrowserRouter>

                    <Container font={this.state.font}>

                        {this.state.langChooseEnabled && <div className={languageChooserClassName}
                                                              onClick={this.toggleChooser}>
                            {this.state.lang.toUpperCase()}
                        </div>}

                        {this.state.languageChooser ?

                            <LanguageChooserContext.Provider
                                value={{
                                    toggleChooser: this.toggleChooser,
                                    ChangLang: this.changeLanguage
                                }}>
                                <LanguageChooser/>
                            </LanguageChooserContext.Provider>
                            : null}


                        <div className={"main-container"}>


                            {this.state.user && this.state.mails && <Mail
                                updateMails={this.updateMails}
                                archiveMails={this.archiveMails}
                                getMails={this.getMails}
                                mails={this.state.mails}/>}

                            <Route path={"/login"} exact render={() => {
                                return this.state.user && this.state.user.is_admin ?
                                    <Redirect to={"/admin"}/> :
                                    <div className="modal">
                                        <label>
                                            <Link to={"/"}><MaterialIcon icon={"close"}/></Link>
                                        </label>
                                        <div className={"modal-content"}>
                                            <Login login={this.login} lang={this.state.lang}/>
                                        </div>
                                    </div>
                            }}
                            />

                            {this.state.user && <div className={"navigation"}>
                                <button onClick={this.logout}><FormattedMessage id={"admin.logout"}/></button>
                            </div>}
                            <MenuContext.Provider
                                value={{
                                    selectedMenu: this.state.menu.selected,
                                    selectedSubMenu: this.state.menu.selectedSub,
                                    setSelectedMenu: this.setSelectedMenu,
                                    fixIt: this.state.fixIt,
                                    is_menu_active: this.state.menu.menu_active,
                                    is_mobile: this.state.is_mobile,
                                    loggedin: this.state.user
                                }}>
                                <Route render={({history}) => (
                                    <Menu
                                        forceUpdate={this.state.menu.forceUpdate}
                                        setSelectedMenu={this.setSelectedMenu}
                                        activateMenu={this.activateMenu}
                                        history={history}
                                    />
                                )}/>

                            </MenuContext.Provider>
                            <Route path="/" exact component={Carousel}/>

                            <div id={"page-content"} className={"page-content"}>
                                <Switch>
                                    <Route path="/" exact
                                           render={() => {
                                               return <Home isLoggedin={this.state.user}
                                                            lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/what_we_do" exact
                                           render={() => {
                                               return <Redirect to={"/what_we_do/products/bits"}/>
                                           }}
                                    />
                                    <Route path="/what_we_do/products" exact
                                           render={() => {
                                               return <Redirect to={"/what_we_do/products/bits"}/>
                                           }}
                                    />
                                    <Route path="/product/:product" exact
                                           render={(props) => {
                                               return <Product {...props} lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/what_we_do/services" exact
                                           render={(props) => {
                                               return <Services {...props} isLoggedin={this.state.user}
                                                                lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/what_we_do/products/:category" exact
                                           render={(props) => {
                                               return <Products {...props} isLoggedin={this.state.user}
                                                                lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/blog/:blogPost" exact
                                           render={(props) => {
                                               return <BlogPost {...props} lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/about" exact
                                           render={() => {
                                               return <Redirect to={"/about/meet_our_team"}/>
                                           }}
                                    />

                                    <Route path="/about/who_we_are"
                                           render={() => {
                                               return <About lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/about/who_we_work_with" exact
                                           render={() => {
                                               return <WWWW lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/about/meet_our_team" exact
                                           render={() => {
                                               return <MOT lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/admin/users" exact
                                           render={() => {
                                               return this.state.user && this.state.user.is_admin ?
                                                   <AdminUsers lang={this.state.lang} userid={this.state.user.id}/> :
                                                   <Redirect to={"/login"}/>
                                           }}
                                    />
                                    <Route path="/admin/products" exact
                                           render={(props) => {
                                               return this.state.user && this.state.user.is_admin ?
                                                   <AdminProducts {...props} lang={this.state.lang}/> :
                                                   <Redirect to={"/login"}/>
                                           }}
                                    />
                                    <Route path="/admin/products/:productId" exact
                                           render={(props) => {
                                               return this.state.user ?
                                                   <AdminProducts {...props} lang={this.state.lang}/> :
                                                   <Redirect to={"/login"}/>
                                           }}
                                    />
                                    <Route path="/admin/posts" exact
                                           render={(props) => {
                                               return this.state.user ?
                                                   <Admin {...props} lang={this.state.lang}/> :
                                                   <Redirect to={"/login"}/>
                                           }}
                                    />

                                    <Route path="/admin/posts/:blogPost" exact
                                           render={(props) => {
                                               return this.state.user ?
                                                   <Admin {...props} lang={this.state.lang}/> :
                                                   <Redirect to={"/login"}/>
                                           }}
                                    />
                                    <Route path="/admin" exact
                                           render={(props) => {
                                               return this.state.user ?
                                                   <Admin {...props} lang={this.state.lang}/> :
                                                   <Redirect to={"/login"}/>
                                           }}
                                    />
                                    {/*
                                    <Route path="/applications" exact
                                           render={() => {
                                               return <Applications lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/applications/civil_engineering" exact
                                           render={() => {
                                               return <Applications_CivilEng lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/applications/mining" exact
                                           render={() => {
                                               return <Applications_Mining lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/applications/water_wells" exact
                                           render={() => {
                                               return <Applications_WaterWells lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/applications/oil_and_gas" exact
                                           render={() => {
                                               return <Applications_OilGas lang={this.state.lang}/>
                                           }}
                                    />*/}
                                    <Route path="/blog" exact
                                           render={() => {
                                               return <Gallery isLoggedin={this.state.user}
                                                               lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route path="/login" exact
                                           render={() => {
                                               return <h3><FormattedMessage id={"admin.please_login"}/></h3>
                                           }}
                                    />
                                    <Route path="/contact" exact
                                           render={() => {
                                               return <Contact lang={this.state.lang}/>
                                           }}
                                    />
                                    <Route render={() => {
                                        return <NF404/>
                                    }}/>
                                </Switch>
                            </div>

                            <Footer/>

                        </div>
                    </Container>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

export default AppWrapper;
