import React from 'react';

import BigTitle from "../BigTitle";
import {API_URL} from "../../Static";
import {FormattedMessage} from "react-intl";
import MaterialIcon from "../MaterialIcon";
import MapGL, {Marker} from '@urbica/react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReCAPTCHA from "react-google-recaptcha";


import fb from "../../assets/img/social/fb.png"
import tw from "../../assets/img/social/tw.png"
import ig from "../../assets/img/social/ig.png"
import li from "../../assets/img/social/li.png"
import marker from "../../assets/img/marker.png"
import contact from "../../assets/img/contact.png"

const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoibW9vbHNieXRoZXdheSIsImEiOiJjamttcTEwZmYwNG55M3BwcG96b2tnb3RkIn0.YS7iCYgWxfJEgUD5-4KDxg";

const meta = require("../../translations/meta.json");

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: {
                latitude: 18.0913304,
                longitude: -15.974945,
                zoom: 13
            },
            valid: false,
            success: false,
            error: false,
            loading: false,
            form: {
                recaptcha: "",
                name: "",
                email: "",
                message: ""
            }
        }

        this.reCaptchaOnChange = this._reCaptchaOnChange.bind(this);
        this.submitForm = this._submitForm.bind(this);
        this.sendmail = this._sendmail.bind(this);
        this.handleChange = this._handleChange.bind(this);
        this.validate = this._validate.bind(this);
    }

    _validate() {
        const valid = this.state.form.email && this.state.form.message && this.state.form.recaptcha
        this.setState({valid})
    }

    _reCaptchaOnChange(value) {
        const form = this.state.form;
        form.recaptcha = value;
        this.setState({form}, this.validate);
    }

    _sendmail() {
        this.setState({
            loading: true
        })

        fetch(API_URL + "/api/contact", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                recaptcha: this.state.form.recaptcha,
                name: this.state.form.name,
                message: this.state.form.message,
                email: this.state.form.email
            })
        }).then((json) => {
            this.setState({
                success: true,
                loading: false,
                form: {
                    name: '',
                    email: '',
                    message: ''
                },
                valid: false

            })
        }).catch((error) => {
            this.setState({
                error: true,
                loading: false
            })
        })
    }

    _handleChange(e) {
        let form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({
            form: form,
            success: false,
            error: false,
            loading: false,
        }, this.validate)
    }

    _submitForm(e) {
        e.preventDefault();
        if (this.state.valid) this.sendmail();
    }

    setViewPort(viewPort) {
        this.setState({viewPort})
    }

    render() {

        return <div className={"contact"}>

            <title>{meta[this.props.lang].contact.title}</title>
            <meta name="description"
                  content={meta[this.props.lang].contact.description}
            />
            <BigTitle title={"headers.contact"}/>
            <img src={contact}/>
            <div className={"r"}>
                <div className={"c-1-2"}>
                    <div className="c-1-1 contact-holder">
                        <a href="mailto:info@unitedmr.com"><MaterialIcon
                            icon={"mail"}/> info@unitedmr.com</a>
                    </div>
                </div>
                <div className={"c-1-2"}>
                    <div className="c-1-1 contact-holder">
                        <a href={"tel:+22236333800"}><MaterialIcon
                            icon={"phone"}/> +(222) 45 25 78 90</a>
                    </div>
                </div>

                <div className={"c-1-4"}>
                    <div style={{backgroundColor: "#172950"}} className="c-1-1 contact-holder"><a
                        style={{color: "#a3beff"}} target={"_blank"}
                        href={"https://www.facebook.com/United-Services-S-A-R-L-628985984283295/"}>
                        <img src={fb}/> facebook</a></div>
                </div>
                <div className={"c-1-4"}>
                    <div style={{backgroundColor: "rgb(1, 110, 193)"}} className="c-1-1 contact-holder"><a
                        style={{color: "#63d0f7"}} target={"_blank"}
                        href={"https://twitter.com/UnitedService13"}> <img src={tw}/> twitter</a></div>
                </div>
                <div className={"c-1-4"}>
                    <div style={{backgroundColor: "#a23488"}} className="c-1-1 contact-holder"><a
                        style={{color: "#f7bb51"}} target={"_blank"}
                        href={"https://www.instagram.com/unitedservices1/"}> <img src={ig}/> instagram</a></div>
                </div>
                <div className={"c-1-4"}>
                    <div style={{backgroundColor: "#a8d3f2"}} className="c-1-1 contact-holder">
                        <a style={{color: "#0093cd"}} target={"_blank"}
                           href={"https://www.linkedin.com/company/united-services-s-a-r-l/about/?viewAsMember=true"}>
                            <img src={li}/> linkedin</a>
                    </div>
                </div>

                <div className={"c-1-1"}>
                    <div className={"c-1-1"}>
                        <MapGL
                            style={{width: '100%', height: '400px'}}
                            mapStyle='mapbox://styles/moolsbytheway/cjwzmz9520qos1cqq5ja7mxv3'
                            accessToken={MAPBOX_ACCESS_TOKEN}
                            latitude={this.state.viewport.latitude}
                            longitude={this.state.viewport.longitude}
                            zoom={this.state.viewport.zoom}
                            onViewportChange={viewport => this.setViewPort(viewport)}
                        ><Marker
                            longitude={this.state.viewport.longitude}
                            latitude={this.state.viewport.latitude}
                        >
                            <img src={marker} alt={"United Services"}/>
                        </Marker>
                        </MapGL>
                    </div>

                </div>
                <div className="c-1-1">
                    <BigTitle h4 title={"headers.contact.by_form"}/>
                    <div className={"r status"}>
                        {!this.state.success && !this.state.valid &&
                        <div className="c-1-1 valid"><FormattedMessage id={"contact.fill_blanks"}/></div>}
                        {this.state.loading &&
                        <div className="c-1-1 loading"><FormattedMessage id={"app.sending"}/></div>}
                        {!this.state.loading && this.state.error &&
                        <div className="c-1-1 error"><FormattedMessage id={"contact.error.sending"}/></div>}
                        {!this.state.loading && this.state.success &&
                        <div className="c-1-1 success"><FormattedMessage id={"contact.success"}/></div>}
                    </div>
                    {!this.state.loading && <div className={"r"}>
                        <div className={"c-1-1"}>
                            <form onSubmit={this.submitForm} className={"contact-form"}>
                                <div className={"r"}>
                                    <div className={"c-1-2"}>
                                        <input value={this.state.form.name}
                                               onChange={this.handleChange}
                                               type={"text"} required placeholder={"Name"} name={"name"}/>
                                    </div>
                                    <div className={"c-1-2"}>
                                        <input value={this.state.form.email}
                                               onChange={this.handleChange}
                                               title={"Field must be a valid e-mail address"} type={"email"} required
                                               placeholder={"E-mail"} name={"email"}/>
                                    </div>

                                </div>
                                <div className={"r"}>
                                    <div className={"c-1-1"}>
                                <textarea value={this.state.form.message}
                                          onChange={this.handleChange}
                                          required placeholder={"Message"} name={"message"}/></div>
                                </div>
                                <div className={"r"}>
                                    <div className={"c-1-1"}>
                                        <ReCAPTCHA
                                            sitekey="6LfTJ6kUAAAAAEVJHsMVPt-GwfpYGI21Gq-L1tW-"
                                            onChange={this.reCaptchaOnChange}
                                        />
                                    </div>
                                </div>
                                <div className={"r"}>
                                    <div className={"c-1-1"}>
                                        <button disabled={!this.state.valid} className={"bold"}>Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    }

}
