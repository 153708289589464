import React from 'react';

import BigTitle from "../BigTitle";
import {Helmet} from "react-helmet";
import {API_URL} from "../../Static";
import renderHTML from "react-render-html";
import {FormattedMessage} from "react-intl";

const meta = require("../../translations/meta.json");

export default class Product extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productid: '',
            product: ''
        }
        this.loadData = this.loadData.bind(this);
        this.productLoaded = this.productLoaded.bind(this);
        this.cachedData = this.cachedData.bind(this);
        Product.getfilename = Product.getfilename.bind(this);

    }

    componentWillReceiveProps(nextProps, nextContext) {
        const oldproductid = this.props.match.params.product;
        const nextproductid = nextProps.match.params.product;
        if (oldproductid === nextproductid) return;

        this.setState({productid: nextproductid}, this.loadData)
    }

    componentWillMount() {
        const productid = this.props.match.params.product;
        this.setState({productid: productid}, this.loadData)
    }

    productLoaded(product) {
        if (product.label) {
            this.setState({product})
        }
    }

    static cacheProduct(product) {
        localStorage.setItem(product.id, JSON.stringify(product));
    }

    cachedData() {
        if (!this.state.productid) return false;
        let cached = false;
        const cacheData = JSON.parse(localStorage.getItem(this.state.productid));
        if (cacheData) {
            this.productLoaded(cacheData);
            cached = true;
        }

        return cached;
    }

    loadData() {
        !this.cachedData() && fetch(API_URL + "/api/products/" + this.state.productid)
            .then(response => response.json())
            .then(product => {
                Product.cacheProduct(product)
                this.productLoaded(product);
            })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });
    }

    render() {
        const product = this.state.product;
        const downloads = product.attachments ? JSON.parse(product.attachments) : [];
        return <div className={"product"}>

            {product && <Helmet>
                <title>{meta[this.props.lang].product.title} {product.label}</title>
                <meta name="description"
                      content={product.short_description}
                />
            </Helmet>}

            {this.state.product && <div>
                <BigTitle title={this.state.product.label}/>
                <div className={"r"}>
                    <div className={"c-2-3"}>
                        <img alt={product.label} width={"100%"}
                             src={"data:image/" + product.imagetype + ";base64," + product.image}/>
                    </div>
                    <div className={"c-1-3"}>
                        <BigTitle title="DOWNLOADS"/>
                        {!downloads || (downloads && downloads.length === 0) &&
                        <p><FormattedMessage id={"product.no_downloads"}/></p>}
                        {downloads && downloads.length > 0 && <ul className={"preview_files"}>
                            {downloads.map((file, i) => {
                                return <li key={i}>
                                    <a download target={"_blank"}
                                       href={API_URL + "/api/viewfile/" + file.name + "/" + Product.getfilename(file.originalName)}>{file.originalName}</a>
                                </li>
                            })
                            }
                        </ul>}
                    </div>

                    <div className={"c-1-1"}>
                        <BigTitle title="OVERVIEW"/>
                        <div className="ql-editor rendered-html">{renderHTML(product.description)}</div>
                    </div>
                </div>
            </div>}

            {!this.state.product && <h2><FormattedMessage id={"app.loading"}/></h2>}
        </div>
    }

    static getfilename(originalName) {
        return originalName.replace(/\./g, "_");
    }
}
