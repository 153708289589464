import React from 'react';

import BigTitle from "../BigTitle";

import img1 from "../../assets/img/apps/1.jpg";
import img3 from "../../assets/img/apps/3.jpg";
import img4 from "../../assets/img/apps/4.jpg";
import img5 from "../../assets/img/apps/5.jpg";
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";


const services = [
    {text: <FormattedMessage id={"applications.civil"}/>, img: img1, link: "/applications/civil_engineering"},
    {text: <FormattedMessage id={"applications.oil"}/>, img: img3, link: "/applications/oil_and_gas"},
    {text: <FormattedMessage id={"applications.mining"}/>, img: img4, link: "/applications/mining"},
    {text: <FormattedMessage id={"applications.water"}/>, img: img5, link: "/applications/water_wells"}
];


const meta = require("../../translations/meta.json");


export default class Applications extends React.Component {

    render() {

        return <div className={"applications"}>

            <Helmet>
                <title>{meta[this.props.lang].applications.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].applications.description}
                />
            </Helmet>
            <BigTitle title={"headers.applications"}/>
            <div className={"r cats"}>
                {services.map((item, i) => {
                    return <div key={i} className={"c-1-2"}>
                        <a href="javascript:void(0)">
                            <span className="bold label">{item.text}</span>
                            {item.extra && <i>{item.extra}</i>}
                            <img width="294" height="164" alt={item.text}
                                 src={item.img}/>
                        </a>
                        {/*<Link to={item.link}>
                            <span className="bold label">{item.text}</span>
                            {item.extra && <i>{item.extra}</i>}
                            <img width="294" height="164" alt={item.text}
                                 src={item.img}/>
                        </Link>*/}
                    </div>
                })}
            </div>
        </div>
    }

}
