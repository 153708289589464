import React from 'react';

import BigTitle from "../BigTitle";


import services from "../../assets/img/services.jpg";

import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";


const meta = require("../../translations/meta.json");


export default class Services extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {

        return <div className={"services"}>


            <Helmet>
                <title>{meta[this.props.lang].services.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].services.description}
                />
            </Helmet>

            <div className={"r"}>
                <div className={"c-1-1"}>
                    <BigTitle icon={services} title={"headers.services"}/>
                    <p><FormattedMessage id={"services.p1"}/></p>
                    <p><FormattedMessage id={"services.p2"}/></p>
                </div>
            </div>
        </div>

    }
}
