import React from 'react';

import BigTitle from "../BigTitle";
import Link from "react-router-dom/Link";

import {Helmet} from "react-helmet";
import {confirmAlert} from "react-confirm-alert";
import {API_URL, product_categories} from "../../Static";
import {FormattedMessage} from "react-intl";


import products from "../../assets/img/products.png";

const meta = require("../../translations/meta.json");


export default class Products extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            last_page: 1,
            limit: 4,
            page: 1,
            searchItem: '',
            category: 'bits',
            searchableProducts: '',
            products: '',
            loading: true
        }
        this.loadData = this.loadData.bind(this)
        this.selectTab = this.selectTab.bind(this)
        this.selectCategory = this.selectCategory.bind(this)
        this.deleteProduct = this.deleteProduct.bind(this)
        this.search = this.search.bind(this)
        this.paginate = this.paginate.bind(this)
        this.productsLoaded = this.productsLoaded.bind(this)
        this.cacheProducts = this.cacheProducts.bind(this)
        this.cachedData = this.cachedData.bind(this)
    }


    deleteProduct(product) {
        confirmAlert({
            title: <FormattedMessage id={"delete.confirm.title"}/>,
            message: <FormattedMessage id={"delete.confirm.body"}/> + " :" + product.label,
            buttons: [
                {
                    label: <FormattedMessage id={"general.yes"}/>,
                    onClick: () => {
                        fetch(API_URL + "/api/products/" + product.id, {
                            method: 'delete', headers: {
                                'Authorization': 'Bearer ' + sessionStorage.getItem("api_key"),
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(res => {
                                // console.log(res);
                            })
                            .catch(error => {
                                console.error(error);
                            })
                            .catch(error => {
                                // // console.log("error", error);
                            }).finally(() => {
                            this.selectTab(this.state.category);
                        })
                    }
                },
                {
                    label: <FormattedMessage id={"general.no"}/>
                }
            ]
        });
    }

    cachedData() {
        let cached = false;
        const cacheData = JSON.parse(localStorage.getItem("products"));
        if (cacheData) {
            if (cacheData[this.state.page]) {
                if (cacheData[this.state.page][this.state.category]) {
                    const data = cacheData[this.state.page][this.state.category];
                    const last_page = localStorage.getItem("last_page")
                    this.productsLoaded(data, last_page);
                    cached = true;
                }
            }
        }

        return cached;
    }

    loadData(cb) {
        this.setState({
            loading: true
        })

        !this.cachedData() && fetch(API_URL + "/api/products?" +
            "category=" + this.state.category +
            "&page=" + this.state.page +
            "&limit=" + this.state.limit)
            .then(response => response.json())
            .then(res => {
                // check if its array
                const data = Array.isArray(res.data) ? res.data : Array.from(Object.keys(res.data), k => res.data[k]);

                this.cacheProducts(data, res.last_page);
                this.productsLoaded(data, res.last_page);
            })
            .catch(error => {
                // // console.log("error", error);
            })
            .catch(error => {
                // // console.log("error", error);
            });
        if (cb) {
            cb();
        }
    }

    productsLoaded(data, last_page) {
        this.setState({
            searchableProducts: data,
            products: data,
            last_page: last_page
        }, () => {
            this.setState({
                loading: false
            })
        });
    }

    cacheProducts(data, last_page) {
        const cacheData = JSON.parse(localStorage.getItem("products"));
        if (!cacheData[this.state.page]) cacheData[this.state.page] = {};
        if (!cacheData[this.state.page][this.state.category]) cacheData[this.state.page][this.state.category] = []
        cacheData[this.state.page][this.state.category] = data;
        localStorage.setItem("products", JSON.stringify(cacheData));
        localStorage.setItem("last_page", last_page)
    }

    search(e) {
        const searchItem = e ? e.target.value : ""
        this.setState({
            searchItem
        })
        const prods = this.state.products.filter(product => {
            return product.label.toLowerCase().match(searchItem) ||
                product.short_description.toLowerCase().match(searchItem);
        });

        this.setState({
            searchableProducts: prods
        })

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.match) {
            const oldcategory = this.props.match.params.category;
            const category = nextProps.match.params.category;
            if (oldcategory === category) return;

            this.setState({category}, this.loadData);
        }
    }

    componentDidMount() {
        this.t2 = setTimeout(() => {
            if (this.state.products.length === 0) {
                this.loadData();
            }
        }, 2000)
    }

    componentWillUnmount() {
        if (!isNaN(this.t)) {
            clearTimeout(this.t)
        }
        if (!isNaN(this.t2)) {
            clearTimeout(this.t2)
        }
    }

    componentWillMount() {
        const that = this;
        this.t = setTimeout(function () {
            if (that.props.match) {
                const category = that.props.match.params.category;
                that.setState({category}, that.loadData)
            }
        }, 500)
    }

    paginate(page) {
        if (page === this.state.page) return;
        this.setState({page}, this.loadData)
    }

    render() {
        const pagination = [];
        for (let i = 1; i <= this.state.last_page; i++) {
            pagination.push(<li key={i}
                                className={this.state.page === i ? "active" : ""}
                                onClick={() => {
                                    this.paginate(i)
                                }}>{i}</li>);
        }

        return <div className={"products"}>

            <Helmet>
                <title>{meta[this.props.lang].products.title}</title>
                <meta name="description"
                      content={meta[this.props.lang].products.description}
                />
            </Helmet>

            <div className={"r"}>
                <div className={"c-1-1"}>
                    <BigTitle icon={products} title={"headers.products"}/>
                    <p className={"products-intro"}><FormattedMessage id={"products.intro"}/></p>
                </div>
            </div>
            <div className={"r"}>
                <div className={"c-1-1 product-categories"}>
                    <select className={"cat-select"} name={"category"} value={this.state.category}
                            onChange={this.selectCategory}>
                        {product_categories.map((cat, i) => {
                            return <option value={cat.id} key={i}>
                                {cat.name}
                            </option>
                        })
                        }</select>

                    <ul className={"cat-tabs"}>
                        {product_categories.map((cat, i) => {
                            return <li key={i}>
                                <span className={this.state.category === cat.id ? "active" : ""}
                                      onClick={() => this.selectTab(cat.id)}>{cat.name}</span>
                            </li>
                        })
                        }
                    </ul>
                    {!this.state.loading && this.state.searchableProducts.length > 0 &&
                    <FormattedMessage id={"products.quick_search"}>
                        {
                            placeholder => (
                                <input onChange={this.search} className={"search-input"} type={"text"}
                                       value={this.state.searchItem} placeholder={placeholder}/>
                            )
                        }
                    </FormattedMessage>}
                </div>
            </div>
            <div className={"r product-items"}>
                {(this.state.category !== "others" && this.state.category !== "tricons") &&
                !this.state.loading && this.state.searchableProducts.length === 0 &&
                <p className={"no-items-found"}><FormattedMessage id={"products.no_items_found"}/></p>}
                {(this.state.category === "others" || this.state.category === "tricons") &&
                <p className={"no-items-found"}><a href={"mailto:info@unitedmr.com"}>
                    <FormattedMessage
                        id={"products.contact_us"}/></a>
                </p>}
                {(this.state.category !== "others" && this.state.category !== "tricons") && this.state.loading &&
                <p className={"no-items-found"}><FormattedMessage id={"app.loading"}/></p>}
                {!this.state.loading && this.state.searchableProducts.length > 0 && this.state.searchableProducts.map((item, i) => {
                    return <div key={i} className={"c-1-4"}>
                        {this.props.isLoggedin && <div className={"product-actions actions"}>
                            <i><Link to={"/admin/products/" + btoa(item.id)}><FormattedMessage
                                id={"products.edit"}/></Link></i>
                            <i className={"delete"} onClick={() => {
                                this.deleteProduct(item)
                            }
                            }><FormattedMessage id={"products.delete"}/></i>
                        </div>}
                        <Link key={i} to={"/product/" + item.id}>
                            <div className="cont">
                                <h5 className="bold label">{item.label}</h5>
                                <div className="product-image"
                                     style={{backgroundImage: "url(data:image/" + item.imagetype + ";base64," + item.image + ")"}}/>
                                <ul>
                                    {item.short_description.split(";;").map((feature, j) => {
                                        return <li key={j}>{feature}</li>
                                    })
                                    }
                                </ul>
                            </div>
                        </Link>
                    </div>
                })}
            </div>
            <ul className={"pagination"}>
                {
                    pagination
                }
            </ul>
        </div>
    }

    selectTab(id) {
        this.setState({
            category: id,
            last_page: 1,
            limit: 4,
            page: 1,
            searchItem: ''
        }, () => {
            this.loadData(function () {
                window.history.pushState(null, null, "/what_we_do/products/" + id);
            });
        })
    }

    selectCategory(e) {
        const cat_id = e.target.value;
        this.selectTab(cat_id)
    }
}
