import React from "react";
import MaterialIcon from "./MaterialIcon";
import {FormattedMessage} from "react-intl";

const Footer = () => {
    return <div className={"footer"} id={"footer"}>
        <div className={"contactus"}>
            <h5 className={"bold footer-title"}>contact</h5>
            <ul>
                <li><MaterialIcon icon={"markunread_mailbox"}/> <FormattedMessage id={"footer.po"}/></li>
                <li><MaterialIcon icon={"location_on"}/> Ilot V # 80 | Nouakchott, Mauritania</li>
                <li><a href={"tel:+22236333800"}><MaterialIcon icon={"phone"}/> +(222) 45 25 78 90</a></li>
                <li><a href="mailto:info@unitedmr.com"><MaterialIcon
                    icon={"mail"}/> info@unitedmr.com</a></li>
            </ul>
        </div>
        <div className={"links"}>
            <h5 className={"bold footer-title"}>connect</h5>
            <ul>
                <li><a style={{color: "#a3beff"}} target={"_blank"}
                       href={"https://www.facebook.com/United-Services-S-A-R-L-628985984283295/"}>facebook</a></li>
                <li><a style={{color: "#63d0f7"}} target={"_blank"}
                       href={"https://twitter.com/UnitedService13"}>twitter</a></li>
                <li><a style={{color: "#f7bb51"}} target={"_blank"}
                       href={"https://www.instagram.com/unitedservices1/"}>instagram</a></li>
                <li><a style={{color: "#0093cd"}} target={"_blank"}
                       href={"https://www.linkedin.com/company/united-services-s-a-r-l/about/?viewAsMember=true"}>linkedin</a>
                </li>
            </ul>
        </div>
        <div className={"copyright"}>
        <span>
            &copy; 2019 - United Services
            </span>
        </div>
    </div>
};

export default Footer;
