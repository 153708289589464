import React from 'react';


const meta = require("../../translations/meta.json");

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logging_in: false,
            credentials: {
                email: '',
                password: ''
            }
        }
        this.handleChange = this._handleChange.bind(this)
        this.submit = this._submit.bind(this)
    }

    _submit(e) {
        e.preventDefault();
        this.setState({
            logging_in: true
        })
        this.props.login(this.state.credentials);
    }

    _handleChange(e) {
        let credentials = this.state.credentials;
        credentials[e.target.name] = e.target.value;
        this.setState({credentials})
    }

    render() {

        return <div className="login">
            <form onSubmit={this.submit}>
                <input type="email" name='email' onChange={this.handleChange} value={this.state.email}/>
                <input type="password" name='password' onChange={this.handleChange} value={this.state.password}/>
                <input disabled={this.state.logging_in} type="submit"
                       value={this.state.logging_in ? "Connecting..." : "Log in"}/>
            </form>
        </div>
    }

}
