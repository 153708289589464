/**
 * Created by moolsbytheway on 8/16/17.
 */

import React from "react";
import MaterialIcon from "../MaterialIcon";
import Error from "../Error";
import CarouselItem from "./CarouselItem";
import CarouselCommand from "./CarouselCommand";


import '../../assets/scss/carousel.scss';
import {FormattedMessage} from "react-intl";


const services = [
    <span>
        <FormattedMessage id={"carousel.hammers"}/>
    </span>,
    <span>
        <FormattedMessage id={"carousel.bits"}/>
    </span>,
    <span>
        <FormattedMessage id={"carousel.drill_rods.1"}/><br/>
        <FormattedMessage id={"carousel.drill_rods.2"}/>
    </span>,
    <span>
        <FormattedMessage id={"carousel.carbide.1"}/><br/>
        <FormattedMessage id={"carousel.carbide.2"}/>
    </span>,
    <span>
        <FormattedMessage id={"carousel.augers.1"}/><br/>
        <FormattedMessage id={"carousel.augers.2"}/>
    </span>,
    <span>
        <FormattedMessage id={"carousel.drilling_tools.1"}/><br/>
        <FormattedMessage id={"carousel.drilling_tools.2"}/>
    </span>,
    <span>
        <FormattedMessage id={"carousel.core_barrels.1"}/><br/>
        <FormattedMessage id={"carousel.core_barrels.2"}/>
    </span>
];


export default class Carousel extends React.Component {
    constructor() {
        super();

        this.state = {
            pause: false,
            bgFailed: false,
            bg: null,
            loaderClass: "hideFade tohide",
            isLoading: true,
            time: 5,
            interval: NaN,
            current: 0
        };

        this.playCarousel = this.playCarousel.bind(this);
        this.pauseCarousel = this.pauseCarousel.bind(this);

        this.CarouselHandleLeftClick = this.CarouselHandleLeftClick.bind(this);
        this.CarouselHandleRightClick = this.CarouselHandleRightClick.bind(this);

        this.loading = this.loading.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.reloadCarouselItem = this.reloadCarouselItem.bind(this);

        this.handleEscapeKeys = this.handleEscapeKeys.bind(this);

        this.loadSuccess = this.loadSuccess.bind(this);
        this.loadFailed = this.loadFailed.bind(this);
        window.addEventListener("keydown", this.handleEscapeKeys);
    }

    handleEscapeKeys(e) {
        switch (e.which) {
            case 37: // left key
                this.CarouselHandleLeftClick();
                break;
            case 39: // right key
                this.CarouselHandleRightClick();
                break;
            default:
                return;
        }
        e.preventDefault();
    }

    static importAll(r) {
        return r.keys().map(r);
    }

    static generateImageList() {
        const paths = require.context('../../assets/img/bg/', false, /\.(png|jpe?g|svg)$/);
        return Carousel.importAll(paths);
    }

    componentWillMount() {
        this.generatedImages = Carousel.generateImageList();
    }

    componentWillUnmount() {
        if (!isNaN(this.t)) {
            clearTimeout(this.t)
        }
        this.stopTimer();
    }

    loading(isTrue) {
        const that = this;
        this.t = setTimeout(function () {
            if (isTrue) {
                that.setState({
                    isLoading: true,
                    loaderClass: ""
                });
                that.stopTimer();
            } else {
                that.setState({
                    isLoading: false,
                    loaderClass: "hideFade "
                });
                that.startTimer();
            }
        }, 500);
    }

    loadFailed() {
        //// // console.log("Bg Loading Failed");
        this.setState({
            bgFailed: true
        });
        this.loading(false);
        this.stopTimer();
    }

    loadSuccess() {
        //// // console.log("Bg Loading Success");
        this.setState({
            bgFailed: false
        });
        this.loading(false);
    }

    loadBg(position) {
        const src = this.generatedImages[position];
        if (src === undefined) {
            this.loadFailed();
        } else {
            let image = new Image();
            image.src = src;
            image.onload = this.loadSuccess;
            image.onerror = this.loadFailed;
        }
    }

    CarouselHandleRightClick(auto) {

        if (auto === true && this.state.pause) return;

        this.loading(true);

        const length = this.generatedImages.length - 1;
        const current = this.state.current === length ? 0 : this.state.current + 1;

        this.setState({current: current, pause: false});

        //// // console.log("getting next item");
        //// // console.log("current item is: " + current);
        // get next item background
        this.loadBg(current);
    }

    CarouselHandleLeftClick() {
        const length = this.generatedImages.length - 1;
        const current = this.state.current === 0 ? length : this.state.current - 1;

        this.setState({current: current, pause: false});
        // show previous item
        //// // console.log("getting previous item");
        //// // console.log("current item is: " + current);

        // that.stopTimer();

        this.loadBg(current);
    }


    startTimer() {
        //// // console.log("Timer Started!");
        this.stopTimer();

        let that = this;
        let interval = setInterval(function () {
            that.CarouselHandleRightClick(true);
        }, that.state.time * 1000);

        this.setState({
            interval: interval
        });
    }

    stopTimer() {
        //// // console.log("Timer Stopped!");
        if (!isNaN(this.state.interval)) {
            clearInterval(this.state.interval);
        }
    }

    reloadCarouselItem() {
        this.loading(true);
        let that = this;
        this.loadBg(this.state.current, function () {
            //// // console.log("Loading Bg");
            that.setState({
                bgFailed: false
            });
            that.loading(false);
        });
    }

    playCarousel() {
        // console.warn("carousel play");
        this.setState({
            pause: false
        });
    }

    pauseCarousel() {
        // console.warn("carousel paused");
        this.setState({
            pause: true
        });
    }

    componentDidMount() {
        this.loading(false);
    }

    render() {

        if (!this.generatedImages.length) {
            return <Error text="Carousel data not loaded"/>
        }
        this.bg = this.state.isLoading ? "" : "url(" + this.generatedImages[this.state.current] + ")";

        return (
            <div className="carousel shadow-0">

                <CarouselCommand isPaused={this.state.pause} play={this.playCarousel} pause={this.pauseCarousel}/>
                <div className="left-arrow" onClick={this.CarouselHandleLeftClick}>
                    <MaterialIcon icon="keyboard_arrow_left"/>
                </div>
                <div className={"carouseLoader tohide " + this.state.loaderClass}>
                    <span className="round"/>
                </div>
                <div className="middle-container center"
                     style={{background: this.bg}}>
                    {this.state.bgFailed ?
                        <Error iconError
                               handleErrorClick={this.reloadCarouselItem}
                               icon="file_download"
                        /> :
                        <CarouselItem text={services[this.state.current]}/>
                    }
                </div>
                <div className="right-arrow" onClick={this.CarouselHandleRightClick}>
                    <MaterialIcon icon="keyboard_arrow_right"/>
                </div>
            </div>
        );
    }
}
